.wr-press-images-download-portlet {
	color: $color-peacock-blue;
	padding: 50px 0; 
	.title {
		margin-bottom: 25px;
		font-size: 2.85em;
		line-height: 1.5;
		font-weight: 300;
		text-align: center;
	}
	.teaser {
		margin-bottom: 50px;
		text-align: center;
		font-size: 1.45em;
		line-height: 1.5;
		font-weight: 500;
	}
	.content {
		margin: 0 5px 10px; 
		transition: .3s all;
		figcaption {
			padding: 15px 0 0 10px;
			.name {
				font-size: 1.2rem;
				font-weight: 500;
				margin-bottom: 0;
			}
			.copyright {
				font-size: 1rem;
			}
		}
		.img-responsive {
			width: 100%;
			height: auto;
			height: 147px;
			object-fit: cover;
			&.portrait {
				object-fit: contain;
				background: #ffffff;
			}
		}
		.downloads {
			padding: 0 0 15px 10px; 

		}
		.download-link {
			display: block;
			padding: 10px 0;
			font-size: 1.2rem;
			vertical-align: middle;
			color: $color-text;
			transition: .3s all;
			&:before {
				margin-right: 15px; 
				vertical-align: middle;
				font-size: 2em;
				color: $color-text;
				text-decoration: none;
			}
			&:hover {
				color: $color-peacock-blue;
				span {
					text-decoration: underline;
				}
			}
		}
		&:hover {
			box-shadow: 0 0 15px rgba(140, 140, 140, 0.5);
		}
	}
	.btns {
		padding: 40px 0 0; 
		text-align: center;
	}
	@media (max-width: $screen-sm-min) {
		.content {
			max-width: 349px; 
			margin: 10px auto; 
		}
	}
	@media (max-width: $screen-xs-min) {
		padding: 25px 0;
		.title {
			margin: 0 0 15px;
			font-size: 2.275em;
			margin-bottom: 15px;
		}
		.teaser {
			margin: 0 0 15px; 
			font-size: 1.275em;
		}
	}
}