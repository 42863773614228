.wr-highlight-topic-slider {
	position: relative;
	.contents {
		background: $color-powder-blue;
		height: 580px;
		.content {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			opacity: 0;
			visibility: hidden;
			transition: .3s all;
			figure {
				display: block;
				height: 100%;
				overflow: hidden;
				background:  linear-gradient(to top, transparentize($color-peacock-blue, 0.25) 15%, transparent 70%);
				img {
					mix-blend-mode: multiply;
				}
				img {
					width: 100%;
					height: 580px;
					object-fit: cover;
					object-position: top;
				}
				.copyright {
					position: absolute;
					right: 20px;
					bottom: 20px;
					color: #ffffff;
					@media (max-width: $screen-sm-min) {
						top: 20px;
						right: 20px;
						left: auto;
						bottom: auto;
					}
				}

			}
			.text {
				position: absolute;
				bottom: 25%;
				color: #ffffff;
				max-width: $container-width;
				h2 {
					font-size: 52px;
					line-height: 1.25;
					font-weight: 500;
				}
				@media (max-width: $screen-sm-min) {
					bottom: auto;
					top: 20px;
					h2 {
						font-size: 32px;
					}
				}
			}
			&.active {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.tabs {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		font-size: 0;
		line-height: 0;
		text-align: center;
		.container {
			position: relative;
		}
		li {
			display: inline-block;
			width: 25%; 
			transition: .4s all;
			border-right: 1px solid  transparentize(#ffffff, 0.3);
			&:last-child {
				border-right: none;
			}
			a {
				display: block;
				padding: 20px 0; 
				font-size: 1.6rem;
				line-height: 1.5;
				color: #fff;
				font-weight: 500;
				cursor: pointer;
				background: transparentize($color-gray-blue, 0.3);
				&:hover {
					background: $color-peacock-blue;
				}
			}
		}
		@media (max-width: $screen-sm-min) {
			.container {
				padding: 0; 
			}
			
			width: 100%; 
			li {
				width: auto; 
				display: block;
				border-right: 0;
				border-bottom: 1px solid $color-gray-blue;
				a {
					padding: 5px 0; 
				}
			}
		}
	}
}