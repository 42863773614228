.wr-video-slider-portlet {
	padding: 50px 0; 
	color: $color-peacock-blue;
	text-align: center;
	.title {
		margin-bottom: 30px;
		font-size: 2.85em;
		line-height: 1.5;
		font-weight: 300;
	}
	.teaser {
		font-size: 1.285em;
		font-weight: 500;
	}
	.wr-video-slider {
		margin-bottom: 50px;
		padding-top: 50px;
		.video-slide {
			text-align: center;
			iframe {
				@media (max-width: $screen-md-min) {
					width: 100%;
				}
			}
		}
		.slick-arrow {
			height: 70px;
			width: 35px;
			// fill: $color-peacock-blue;
		}
		
	}
	.btns {
		text-align: center;
	}

	@media (max-width: $screen-md-min) {
		iframe {
			width: 100%;
		}
	}
	@media (max-width: $screen-xs-min) {
		padding: 25px 0;
		.title {
			font-size: 2.275em;
			margin-bottom: 15px;
		}
		.teaser {
			font-size: 1.275em;
		}
	}
}
