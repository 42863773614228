.wr-banner-portlet,
.wr-image-banner-portlet {
	position: relative;
	overflow: hidden;
	color: $color-text;
	text-align: center;
	fill: $color-peacock-blue;
	.container {
		position: relative;
		padding: 70px 10px;
		@media (max-width: $screen-xs-min) {
			padding: 35px 10px; 
		}
	}
	.choose {
		display: inline-block;
		margin: 0 15px;
		label {
			color: inherit;
			&:before,
			&:after {
				border-color: inherit ;
			}
			&:before {
				border: none;
				background-color: #ffffff;
				box-shadow: inset 0 0 4px $color-peacock-blue;
			}
		}
	}
	.title {
		font-size: 3.2rem;
		line-height: 4.3rem;
		font-weight: 300;
		margin-bottom: 50px;
	}
	.teaser {
		font-size: 1.8rem;
		line-height: 2.6rem;
		font-weight: 600;
		margin-bottom: 50px;
	}
	.icon-svg {
		width: 40px;
		height: 40px;
		fill: inherit;
		.f-path {
			fill: #fff;
		}
	}
	.wr-social-btns {
		a {
			display: inline-block;
			padding: 0 50px; 
		}
		@media (max-width: $screen-xs-min) {
			a {
				padding: 0 15px; 
			}
		}
	}
	&.bg-peacock-blue {
		color: #fff;
		fill: #fff;
		.f-path {
			fill: $color-peacock-blue;
		}
		.wr-btn {
			color: inherit;
			border-color: #fff;
		}
	}
	&.bg-magenta {
		.f-path {
			fill: none;
		}
	}
}

.wr-image-banner-portlet {
	color: #ffffff;
	border-color: #ffffff;
	// $blend-color, $fallback-color, $opacity, $selector
	@include blend-multiply($color-peacock-blue, #0000ff, 0.25, '.overlay');
	.copyright {
		position: absolute;
		right: 0;
		bottom: 20px;
		font-size: 1.2rem;
		line-height: 1.25;
		color: inherit;
	}

	.overlay {
		top: 0;
		left: 0;
		position: absolute;
		background: inherit;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
	// @supports (mix-blend-mode: multiply) {
	// 	background-color: $color-peacock-blue;
	// 	.overlay {
	// 		opacity: 1;
	// 		mix-blend-mode: multiply;
	// 	}
	// }
}