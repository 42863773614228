input {
	border: 1px solid $color-gray-md;
}
input:focus {
	outline: none;
	border-bottom-color: $color-primary;
	color: $color-primary;
	&::placeholder {
		color: $color-primary;
	}
	&::-moz-placeholder {
		color: $color-primary;
	}
	&::-webkit-placeholder {
		color: $color-primary;
	}
	&:-ms-input-placeholder {
		color: $color-primary;
	}
}
// input::-webkit-outer-spin-button /* field spin arrows */
// input::-webkit-inner-spin-button /* field spin arrows */
input::-webkit-clear-button { /* Removes blue cross */
  -webkit-appearance: none;
  margin: 0;
}
input[name*='captcha'] {
	padding: 0.375rem 0.75rem;
}
.invalid-feedback {
	display: none;
	font-size: 1rem;
	line-height: 1.5;
}
.valid-feedback {
	display: none;
}
.form-link {
	font-size: 0.85em;
	text-decoration: underline;
}
.wr-field {
	// &[type="text"],
	// &[type="number"],
	// &[type="password"],
	// &[type="email"],
	// &[type="date"],
	// &[type="time"],
	// &[type="day"],
	// &[type="week"],
	// &[type="month"],
	// &[type="year"],
	// &[type="search"],
	// &[type="tel"],
	// &[type="url"] {
		&:not([type="radio"]),
		&:not([type="checkbox"]) {
		height: auto;
		padding: 10px 15px;
		font-size: 1.335rem;
		font-weight: 500;
		line-height: 1.4;
		border-radius: 0;
		background: #ffffff;
		box-shadow: none;
		border-style: solid;
		border-width: 1px;
		border-color: $color-peacock-blue;
		resize: vertical;
		-moz-appearance: none;
		-webkit-appearance: none;
		transition: .3s all;

		&::-ms-expand {
			display: none;
		}

		&::placeholder {
			color: $color-gray-md;
			opacity: 0.8;
		}
		&:-ms-input-placeholder {
			color: $color-gray-md;
			opacity: 0.8;
		}
		&::-ms-input-placeholder {
			color: $color-gray-md;
			opacity: 0.8;
		}

		&.is-invalid {
			border-color: $color-error;
			color: $color-error;
			&:focus {
				border-color: $color-error;
				color: $color-gray-dk;
			}
			&::placeholder {
				color: $color-error;
				opacity: 1;
			}
			&:-ms-input-placeholder {
				color: $color-error;
				opacity: 1;
			}
			&::-ms-input-placeholder {
				color: $color-error;
				opacity: 1;
			}
			& + .invalid-feedback {
				display: block;
				padding: 10px 15px;
				color: $color-error;
			}
		}
		&.is-valid {
			border-color: $color-success;
			& + .valid-feedback {
				display: block;
				padding: 5px 15px;
				color: $color-success;
			}
		}
		&:focus {
			box-shadow: none;
			border-color: $color-faded-blue;
			&::placeholder {
				color: $color-peacock-blue;
			}
			&::-moz-placeholder {
				color: $color-peacock-blue;
			}
			&::-webkit-placeholder {
				color: $color-peacock-blue;
			}
			&:-ms-input-placeholder {
				color: $color-peacock-blue;
			}
		}
	}
}
.wr-select {
	position: relative;
	select.wr-field {
		padding-right: 50px;
		font-weight: inherit;
		font-size: inherit;
		line-height: inherit;
		color: inherit;
	}
	option {
		// font-size: 1.2em;
	}
	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		right: 1.4em;
		display: block;
		height: 0.85em;
		width: 1.5px;
		background: $color-peacock-blue;
		transform: translateY(-50%);
	}
	&:before {
		right: 2.0em;
		transform: translateY(-50%) rotate(-50deg);
	}
	&:after {
		transform: translateY(-50%) rotate(50deg);
	}
	.wr-select-list {
		display: none;
	}
}
.wr-field-upload {
	position: relative;
	.placeholder {
		display: block;
		text-align: left;
		color: $color-gray-md;
		opacity: 0.8;
		font-size: 1.6rem;
		line-height: 2.6rem;

	}
	input[type="file"] {
		height: 0;
		width: 0;
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 0;
		left: 0;
	}
	& ~ label.wr-btn {
		font-size: 1.6rem;
		line-height: 1.2;
	}
}
.wr-multiple-select-wrapper {
	display: inline-block;
	.wr-multiple-select {
		position: relative;
		select.wr-field {
			padding-right: 50px;
			font-weight: inherit;
			font-size: inherit;
			line-height: inherit;
			color: inherit;
			border-color: $color-peacock-blue;
		}
		option {
			font-size: 1.2em;
		}

		.wr-select-list {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			max-height: 250px;
			overflow: auto;
			background: #fff;
			border-style: solid;
			border-width: 1px;
			border-top-width: 0;
			border-color: $color-peacock-blue;
			z-index: 1;
			scrollbar-width: none;
			li {
				color: $color-peacock-blue;
				font-family: "Fira Sans", sans-serif;
				font-weight: 700;

				&.selected {
					background-color: #ccdbeb;
				}
				input {
					display: none;
				}
				label {
					display: block;
					padding: 10px 15px;
					width: 100%;
					margin: 0;
					word-wrap: break-word;
				}
			}
			@include scrollbar();
		}
		&.has-flag {
			.wr-select-list {
				display: block;
			}
		}
	}

	.wr-multiple-select-control {
		&.wr-field{
			display: inline-block;
			position: relative;
			min-width: 140px;
			padding: 10px 55px 10px 15px;
			color: $color-peacock-blue;
			font-family: "Fira Sans", sans-serif;
			font-size: 18px;
			font-weight: 700;
		}
		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: 1.4em;
			display: block;
			height: 0.85em;
			width: 1.5px;
			background: $color-peacock-blue;
			transform: translateY(-50%);
		}
		&:before {
			right: 2.0em;
			transform: translateY(-50%) rotate(-50deg);
		}
		&:after {
			transform: translateY(-50%) rotate(50deg);
		}
	}
}

.choose {
	position: relative;
	&.check {
		label {
			margin: 0;
			font-size: 1.6rem;
			line-height: 1.5;
			color: $color-text;
			&:before,
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 2.6rem;
				height: 2.6rem;
				text-align: center;
				line-height: 1.25rem;
			}
			&:before {
				border: 1px solid $color-peacock-blue;
			}
			&:after {
				width: 1.7rem;
				height: 0.8rem;
				top: 0.65rem;
				left: 0.475rem;
				transition: .2s all;
				border-width: 0 0 1.5px 1.5px;
				border-style: solid;
				border-color: transparent;
				opacity: 0;
			}
			a {
				text-decoration: underline;
			}
		}
		input[type="checkbox"] {
			appearance: none;
			-webkit-appearance: none;
			opacity: 0;
			margin: 0 1rem 0 0;
			width: 2.6rem;
			height: 2.6rem;
			line-height: 2.6rem;
			vertical-align: top;
			&:checked + label:after {
				transform: rotate(-45deg);
				border-color: transparent transparent $color-peacock-blue $color-peacock-blue;
				opacity: 1;
			}
			&.is-invalid + label {
				color: $color-error;
				&:before,
				&:after {
					border-color: $color-error;
				}
			}
		}
	}
	&.radio {
		label {
			margin: 0;
			font-size: 1.6rem;
			line-height: 1.5;
			color: $color-text;
			font-weight: 500;
			padding: 0;
			min-height: unset;
			&:before,
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 2rem;
				height: 2rem;
				text-align: center;
				line-height: 1.25rem;
				border-radius: 50%;
			}
			&:before {
				box-shadow: inset 0 0 3px $color-gray-md;
				border: 1px solid $color-peacock-blue;
			}
			&:after {
				width: 1.2rem;
				height: 1.2rem;
				top: 0.4rem;
				left: 0.4rem;
				transition: .2s all;
			}
			a {
				text-decoration: underline;
			}
		}
		input[type="radio"] {
			position: relative;
			appearance: none;
			-webkit-appearance: none;
			opacity: 0;
			margin: 0 1rem 0 0;
			width: 2rem;
			height: 2rem;
			line-height: 2.6rem;
			vertical-align: top;
			&:checked + label:after {
				position: absolute;
				background: $color-peacock-blue;
			}
			&.is-invalid + label {
				color: $color-error;
				&:before,
				&:after {
					border-color: $color-error;
				}
			}
		}
	}
	&.is-invalid {
		label {
			color: $color-error;
			&:before,
			&:after {
				border-color: $color-error;
			}
		}
	}
}

.wr-datepicker {
	position: relative;
	input {
		padding: 5px 15px;
		position: relative;
		text-overflow: ellipsis;
		&.is-invalid {
			border-color: $color-error;
			color: $color-error;
		}
		&.is-invalid + .invalid-feedback {
			display: block;
		}
	}
	.datepicker {
		&.datepicker-dropdown {
			width: 100%;
			border-radius: 0;
			&:before,
			&:after {
				content: none;
			}
			.datepicker-days {
				.dow {
					color: #848484;
					font-size: 10px;
					font-weight: 500;
					line-height: 15px;
				}
				.day {
					padding: 5px 0;
					font-size: 16px;

					&:hover {
						background: none;
						color: $color-primary;
					}
				}
				.new,
				.old {
					font-size: 0;
					line-height: 0;
					padding: 0;
					height: 0;
				}
			}
			table.table-condensed {
				width: 100%;
			}
		}
	}
}

.form-container {
	max-width: 780px;
	margin: 0 auto;
	@media (max-width: 780px) {
		max-width: 100%;
	}
}

.form-label {
	text-align: left;
	&.required {
		&:after {
			content: '*';
			font-size: inherit;
			line-height: inherit;
			color: inherit;
		}
	}
}

.form-login {
	color: $color-peacock-blue;
	text-align: center;
	margin: 0 auto;
	padding: 50px 0;
	.title {
		font-size: 2.85em;
		line-height: 1.25;
		margin-bottom: 50px;
	}
	.teaser {
		font-size: 20px;
		line-height: 1.25;
		margin-bottom: 50px;
	}
	.form-label {
		font-size: 1.42em;
		line-height: 2.4em;
		font-weight: 500;
	}
	.form-group {
		text-align: left;
		width: 85%;
		margin: 0 auto 40px;
	}
	.form-link {
		padding-left: 15px;
	}
	.registration-message {
		float: none;
		margin: 0 auto;
		font-size: 1.125em;
		font-weight: 500;
		a {
			text-decoration: underline;
		}
	}
}

.form-formular {
	color: $color-peacock-blue;
	text-align: center;
	padding: 0 0 50px;
	.form-header {
		margin-bottom: 50px;
		padding-bottom: 40px;
		border-bottom: 1px solid $color-peacock-blue;
		.title {
			font-size: 3.715em;
			line-height: 1.25;
			margin-bottom: 50px;
		}
		.teaser {
			font-size: 20px;
			line-height: 1.25;
			margin-bottom: 50px;
		}
	}
	.form-group {
		font-size: 0;
		line-height: 0;
		text-align: left;
		width: 60%;
		margin: 0 auto 40px;
		[class*="col-"] {
			display: inline-block;
			float: none;
			padding: 0;
			vertical-align: top;
			&.form-label {
				padding: 10px 0;
			}
		}
		input {
			&:not([type='radio'], [type='checkbox']) {
				vertical-align: middle;
				font-size: 1.6rem;
				line-height: 2.6rem;
			}
		}
		.choose.check {
			margin-bottom: 15px;
			label {
				color: $color-peacock-blue;
				font-weight: 500;
				max-width: calc(100% - 3.6rem);
			}
		}
		.choose.radio {
			display: inline-block;
			width: 50%;
		}
		.wr-select {
			display: inline-block;
			width: auto;
			color: $color-peacock-blue;
			font-weight: 500;
			vertical-align: middle;
			font-size: 1.6rem;
			line-height: 2.6rem;

		}
	}
	.form-label {
		font-size: 2rem;
		line-height: 2.6rem;
		font-weight: 500;
		vertical-align: middle;
		margin: 0;
		& ~ .wr-textarea {
			margin-top: 10px;
		}
	}
	.wr-btns {
		text-align: center;
		& > div {
			display: inline-block;
			text-align: left;
			.btn-text {
				padding-top: 10px;
				font-size: 1.2rem;
				line-height: 1.5;
				color: $color-text;
			}
		}
	}
	@media (max-width: $screen-sm-min) {
		.form-group {
			width: 100%;
			margin: 0 auto 10px;
			[class*="col-"] {
				display: block;
				width: 100%;
			}
		}
	}
}

.form-newsletter {
	margin: 0 0 70px;
	color: $color-peacock-blue;
	text-align: center;
	.form-header {
		margin-bottom: 20px;
		.title {
			font-size: 2.857em;
			line-height: 1.25;
			margin: 0 0 50px;
		}
		.teaser {
			color: #333333;
			font-size: 20px;
			line-height: 34px;
		}
	}
	.wr-options-list {
		margin-bottom: 120px;
	}
	.wr-information-list {
		margin-bottom: 50px;
	}
	.form-group {
		font-size: 0;
		line-height: 0;
		text-align: left;
		margin-bottom: 20px;
		[class*="col-"] {
			display: inline-block;
			float: none;
			padding: 0;
			vertical-align: top;
			&.form-label {
				padding: 10px 0;
			}
		}
		.choose.check {
			margin-bottom: 15px;
			label {
				float: left;
				color: #333333;
				font-weight: 500;
				width: 85%;
				@media (min-width: $screen-md-min) {
					width: 100%
				}
				@media (min-width: $screen-xs-min) {
					width: 90%
				}
			}
			input[type="checkbox"] {
				float: left;
				margin-right: 10px;
			}
			p {
				margin: 0;
			}
		}
	}
	@media (min-width: $screen-sm-min) {
		margin: 70px 80px;
	}
	@media (min-width: $screen-xs-min) {
		margin: 70px 50px;
	}
}

.form-change-pass {
	color: $color-peacock-blue;
	text-align: center;
	margin: 0 auto;
	padding-bottom: 50px;
	.title {
		font-size: 2.85em;
		line-height: 1.25;
		margin-bottom: 50px;
	}
	.teaser {
		font-size: 20px;
		line-height: 1.25;
		margin-bottom: 50px;
	}
	.form-label {
		font-size: 1.42em;
		line-height: 1.4em;
		font-weight: 500;
	}
	.form-group {
		text-align: left;
		width: 85%;
		margin: 0 auto 40px;
	}
	.form-link {
		padding-left: 15px;
	}
	.registration-message {
		float: none;
		margin: 0 auto;
		font-size: 1.125em;
		font-weight: 500;
		a {
			text-decoration: underline;
		}
	}
}

.form-comment {
	.wr-btns {
	text-align: right;
		.wr-btn {
			padding: 2px 13px;
			font-size: 16px;
			line-height: 24px;
			font-weight: 400;
			&:before {
				font-size: 15px;
			}
		}
	}
	.wr-textarea {
		margin-bottom: 20px;
		textarea {
			&::placeholder {
				font-size: 16px;
				line-height: 24px;
				font-weight: 600px;
			}
		}
	}
}

.form-my-info {
	color: $color-peacock-blue;
	text-align: center;
	// padding: 0 0 50px;
	.form-header {
		margin-bottom: 50px;
		padding-bottom: 40px;
		border-bottom: 1px solid $color-peacock-blue;
		.title {
			font-size: 3.715em;
			line-height: 1.25;
			margin-bottom: 50px;
		}
		.teaser {
			font-size: 20px;
			line-height: 1.25;
			margin-bottom: 50px;
		}
	}
	.form-group {
		font-size: 0;
		line-height: 0;
		text-align: left;
		width: 80%;
		margin: 0 auto 40px;
		[class*="col-"] {
			display: inline-block;
			float: none;
			padding: 0;
			vertical-align: top;
			&.form-label {
				padding: 10px 0;
			}
		}
		input {
			&:not([type='radio'], [type='checkbox']) {
				vertical-align: middle;
				font-size: 1.6rem;
				line-height: 2.6rem;
			}
		}
		.choose.check {
			margin-bottom: 15px;
			label {
				color: $color-peacock-blue;
				font-weight: 500;
				max-width: calc(100% - 3.6rem);
			}
		}
		.choose.radio {
			display: inline-block;
			width: 50%;
		}
		.wr-select {
			display: inline-block;
			width: auto;
			color: $color-peacock-blue;
			font-weight: 500;
			vertical-align: middle;
			font-size: 1.6rem;
			line-height: 2.6rem;

		}
	}
	.form-label {
		font-size: 2rem;
		line-height: 2.6rem;
		font-weight: 500;
		vertical-align: middle;
		margin: 0;
		& ~ .wr-textarea {
			margin-top: 10px;
		}
	}
	.wr-btns {
		text-align: center;
		& > div {
			display: inline-block;
			text-align: left;
			.btn-text {
				padding-top: 10px;
				font-size: 1.2rem;
				line-height: 1.5;
				color: $color-text;
			}
		}
		&--edit-profile {
			display: inline-block;
			font-size: 1.6rem;
			text-align: right;
			line-height: 1.5;
			.wr-btn {
				font-size: inherit;
				margin-bottom: 0;
	    	width: 70%;
	    	margin-left: auto;
			}

		}
	}
	@media (max-width: $screen-sm-min) {
		.form-group {
			width: 100%;
			[class*="col-"] {
				display: block;
				width: 100%;
			}
		}
	}
	.msg {
		font-size: 1.2rem;
		line-height: 1.5;
		padding: 1rem;
		&--success {
			background: lighten($color-success, 35);
			color: darken($color-success, 15);
		}
		&--error {

		}
	}
}

.form-privacy-settings {
	color: $color-peacock-blue;
	text-align: center;
	padding: 0 0 50px;
	.form-header {
		text-align: left;
		.title {
			color: $color-peacock-blue;
			// font-size: 3.715em;
			line-height: 1.25;
			margin-bottom: 50px;
		}
		.teaser {
			color: $color-peacock-blue;
			font-size: 20px;
			line-height: 1.25;
			margin-bottom: 50px;
		}
	}
	.form-group-title {
		color: #333333;
		font-size: 1.8rem;
		line-height: 3rem;
		font-weight: 500;
	}
	.form-group {
		font-size: 0;
		line-height: 0;
		text-align: left;
		width: 100%;
		margin: 0 auto 20px;
		[class*="col-"] {
			display: inline-block;
			float: none;
			padding: 0;
			vertical-align: top;
			&.form-label {
				padding: 10px 0;
			}
		}
		.form-richtext {
			font-size: 1.8rem;
			line-height: 3rem;
			color: #333333;
		}
		input {
			&:not([type='radio'], [type='checkbox']) {
				vertical-align: middle;
				font-size: 1.6rem;
				line-height: 2.6rem;
			}
		}
		.choose.check {
			margin-bottom: 15px;
			label {
				color: $color-peacock-blue;
				font-weight: 500;
				max-width: calc(100% - 3.6rem);
			}
		}
		.choose.radio {
			display: inline-block;
			width: 25%;
		}
		.wr-select {
			display: inline-block;
			width: auto;
			color: $color-peacock-blue;
			font-weight: 500;
			vertical-align: middle;
			font-size: 1.6rem;
			line-height: 2.6rem;

		}
	}
	.form-label {
		font-size: 1.8rem;
		line-height: 3rem;
		font-weight: 400;
		vertical-align: middle;
		margin: 0;
		color: #333333;
		& ~ .wr-textarea {
			margin-top: 10px;
		}
	}
	.wr-btns {
		text-align: center;
		& > div {
			display: inline-block;
			text-align: left;
			.btn-text {
				padding-top: 10px;
				font-size: 1.2rem;
				line-height: 1.5;
				color: $color-text;
			}
		}
	}
	@media (max-width: $screen-sm-min) {
		.form-group {
			width: 100%;
			[class*="col-"] {
				display: block;
				width: 100%;
			}
		}
	}
}
.form-send-email {
	padding: 25px; 
	.form-label[class*='col-'] {
		padding: 10px 0;
		margin: 0; 
	}
	.btns {
		text-align: center;
	}
	.wr-btn {
		font-size: 1em;
	}
}
.form-question {
	.choose {
		label {
			color: inherit;
			&:before {

			}
		}
	}
}