/* ------------------------------------------------------------ *\
	#settings
\* ------------------------------------------------------------ */
* { margin: 0; padding: 0; box-sizing: border-box; }
*:focus { outline: none; }

.js-container { overflow: hidden; }
.container {
	width: 100%;
	padding: 0 10px;
	@media all and (min-width: $container-width) {
		width: $container-width;
		&.reduced {
			width: 800px;
		}
	}
}
.wrapper {
	max-width: $wrapper-width;
	margin: 0 auto;
	position: relative;
}
.main {

}
body {
	overflow-y: scroll;
	font-family: $font-primary;
}
a {
	color: $color-peacock-blue;
	&:hover,
	&:focus {
		color: $color-peacock-blue;
		text-decoration: none;
	}
}
mark {
	padding: 0;
	margin: 0;
	background: $color-powder-blue;
}
ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.rich-text {
	ul {
		margin-bottom: 10px;
		list-style: initial;
		list-style-position: inside;
	}
}
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
p {
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	word-break: break-word;
}
.rich-text {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $color-peacock-blue;
	}
	iframe {
		width: 100%;
	}
}
img {
	height: auto;
}


/* ------------------------------------------------------------ *\
	#z-index
\* ------------------------------------------------------------ */
.header,
.footer {
	position: relative;
	width: 100%;
}
.header {
	z-index: 40;
}
.main {
	z-index: 30;
}
.footer {
	z-index: 20;
}
.wr-accents {
	z-index: 10;
}
