.wr-quote-portlet {
	background-color: $color-peacock-blue;
	color: #ffffff;
	padding: 70px 0px;

	.clearfix {
		margin-bottom: 80px;
	}

	.wr-quote-title {
		font-weight: 600;
		font-size: 18px;
		line-height: 26px;
		margin-bottom: 35px;
	}

	.wr-quote-text {
		float: left;
		font-weight: 300;
		font-size: 32px;
		max-width: 75%;
		margin-right: 25px;
		padding-top: 15px;
	}

	.wr-quote-image {
		float: right;
		max-width: 20%;
		text-align: center;
		img {
			width: 160px;
			height: 160px;
			margin: 0 auto; 
			border-radius: 100%;
			display: block;
			margin-bottom: 20px;
		}
		.wr-image-caption {
			font-weight: 500;
			font-size: 24px;
		}
	}
	@media (max-width: $screen-sm-min) {
		padding: 35px 0;
		.wr-quote-title {
			margin-bottom: 10px;
			text-align: center;
		}
		.clearfix {
			margin-bottom: 15px;
		}
		.wr-quote-image,
		.wr-quote-text {
			float: none;
			max-width: 100%;
			margin: 0 auto;
			font-size: 24px;
		}
		.wr-quote-image {
			padding: 10px 0 30px;
			text-align: center;
			img {
				margin: 0 auto 20px;
			}
		}
	}
}