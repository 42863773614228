.wr-paginator {
	color: $color-peacock-blue;
	font-weight: 700;
	text-align: center;
	.page-item {
		.page-link {
			background: #fff;
			border-radius: 0;
			border-color: $color-peacock-blue;
			padding: 8px 15px;
			&:hover {
				background: $color-powder-blue;
			}
			&:before {
				font-weight: 900;
				color: inherit;
			}
			&[aria-label] {
				padding: 5px 15px;
				border: none;
				margin: 0;
				&:before {
					font-size: 1.75em;
					line-height: 1;
				}
				&:hover {
					background: none;
				}
			}
		}
		&.active {
			.page-link {
				background: $color-peacock-blue;
				color: #fff;
			}
		}
	}
	@media only screen and (max-width: 479px) {
		li > a {
			padding: 0 5px;
			margin: 0 2px; 
		}
	}
}