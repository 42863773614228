.wr-topic-slider {
	position: relative;
	.heading {
		text-align: center;
		padding: 70px;
		color: $color-peacock-blue;
		.title {
			font-size: 4rem;
			line-height: 5rem;
			margin-bottom: 20px;
			font-weight: 300;
		}
		.teaser {
			font-size: 1.8rem;
			line-height: 2.6rem;
			font-weight: 600;
		}
	}
	.contents {
		background: $color-powder-blue;
		height: 580px;
		.content {
			position: absolute;
			width: 100%;
			opacity: 0;
			visibility: hidden;
			transition: .3s all;
			figure {
				overflow: hidden;
				img {
					width: 100%;
					height: 580px;
					object-fit: cover;
					object-position: top;
				}
				.copyright {
					position: absolute;
					right: 20px;
					bottom: 20px;
					color: #ffffff;
				}
			}
			&.active {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.tabs {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		font-size: 0;
		line-height: 0;
		text-align: center;
		.container {
			position: relative;
		}
		li {
			position: relative;
			display: inline-block;
			width: 20%; 
			background: transparentize($color-gray-blue, 0.3);
			border-right: 1px solid  transparentize(#ffffff, 0.3);
			&:last-child {
				border-right: none;
			}
			span,
			a {
				position: relative;
				display: block;
				padding: 20px 0; 
				font-size: 1.4rem;
				line-height: 1.5;
				color: #fff;
				font-weight: 500;
				cursor: pointer;
				transition: .4s all;
			}
			.topic-info {
				display: none;
				position: absolute;
				bottom: 100%;
				left: 0; 
				width: 200%;
				height: 265px;
				padding: 20px 15px; 
				text-align: left; 
				background: inherit;
				transition: .2s background;
				font-size: 1.6rem;
				line-height: 1.2;
				color: #fff;
				.title {
					font-size: 1.125em;
					line-height: 1.45;
					margin: 0 0 15px;
					font-weight: 600;
				}
				.text {
					font-size: 1em;
					line-height: 1.45;
					margin-bottom: 20px;
					font-weight: 300;
					height: 6.12em;
					overflow: hidden;
				}
				.wr-btn {
					display: inline-block;
					padding: 5px 20px; 
					font-weight: 300;
				}
			}
			&:hover { 
				background: $color-peacock-blue;
				.topic-info {
					display: block;
					animation: bottom-fade-in .3s;
				}
			}
			&:last-child {
				.topic-info {
					left: auto; 
					right: 0;
				}
			}
		}
		@media (max-width: $screen-md-min) {
			.container {
				padding: 0;
			}
			ul {
				float: left;
				position: relative;
				min-width: 200px;
			}
			li {
				width: auto; 
				position: static;
				display: block;
				border: none;
				.topic-info {
					bottom: 0;
					left: 100%;
					width: 150%;
					min-height: 100%;
					.text {
						height: auto;
						max-height: 60%;
						overflow: auto;
					}
				}
				&:last-child {
					.topic-info {
						bottom: 0;
						left: 100%;
						width: 150%;
					}
				}
				&:hover {
					.topic-info {
						animation: left-fade-in .3s;
					}
				}
			}
		}
		@media (max-width: $screen-xs-min) {
			ul {
				float: none;
				position: relative;
			}
			li {
				width: auto; 
				position: static;
				display: block;
				span {
					padding: 10px 0; 
					border-bottom: 1px solid $color-gray-blue; 
				}
				.topic-info {
					position: static;
					width: auto;
					.text {
						height: auto;
						max-height: 60%;
						overflow: auto;
					}
				}
				&:hover {
					background: none;
					span {
						background-color: rgba(128, 166, 205, 0.9);
					}
					.topic-info {
						animation: bottom-fade-in .3s;
						background-color: rgba(128, 166, 205, 0.5);
					}
				}
			}
		}
	}
	@media (max-width: $screen-xs-min) {
		.heading {
			padding: 15px;
			.title {
				font-size: 2.275em;
				margin: 0 0 15px;
			}
			.teaser {
				font-size: 1.275em;
			}
		}
	}
}