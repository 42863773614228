$icomoon-font-family: "wr-iconfont" !default;
$icomoon-font-path: "../../fonts/webfonts/wr-iconfonts" !default;

$wr-wf-add-icon: "\e91a";
$wr-wf-presse-news: "\e900";
$wr-wf-member-1: "\e901";
$wr-wf-member-2: "\e902";
$wr-wf-presse: "\e903";
$wr-wf-contacts: "\e904";
$wr-wf-profile: "\e905";
$wr-wf-linkedin: "\e906";
$wr-wf-twitter: "\e907";
$wr-wf-youtube: "\e908";
$wr-wf-button-arrow: "\e909";
$wr-wf-calendar: "\e90a";
$wr-wf-close: "\e90b";
$wr-wf-contact: "\e90c";
$wr-wf-document: "\e90d";
$wr-wf-download: "\e90e";
$wr-wf-facebook: "\e90f";
$wr-wf-label: "\e910";
$wr-wf-link: "\e911";
$wr-wf-mail: "\e912";
$wr-wf-mail-outline: "\e913";
$wr-wf-pdf: "\e914";
$wr-wf-phone: "\e915";
$wr-wf-location: "\e916";
$wr-wf-print: "\e917";
$wr-wf-arrow-left: "\e918";
$wr-wf-arrow-right: "\e919";

