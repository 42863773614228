.wr-nav-primary {
	padding-top: 10px;
	padding-bottom: 15px;
}

.wr-navigation {
	padding: 5px 0;
	margin: 0;
	font-size: 0;
	line-height: 0;
	ul {
		padding: 0;
		margin: 0;
	}
	& > li {
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		& > *:first-child {
			position: relative;
			text-decoration: none;
		}
		& > *:first-child:before {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			display: block;
			height: 24px;
			transform: translateY(-50%);
			width: 1px;
			background: #000;
		}
		&:first-child > *:first-child {
			&:before {
				content: none;
			}
		}
		& > a {
			display: block;
			position: relative;
			font-size: 1.2rem;
			line-height: 1.5;
			max-width: 95px;
			padding: 0 9px;
			vertical-align: middle;
			color: $color-text;
		}

		&.has-dropdown {
			position: relative;
			.dropdown {
				position: absolute;
				left: 0;
				top: 100%;
				width: 100%; 
				opacity: 0;
				font-size: 1.6rem;
				line-height: 1.5;
				transform: scaleY(0.3);
				transform-origin: top center;
				transition: .4s all;
				text-align: center;
				z-index: 102; 
				&.open {
					opacity: 1;
					transform: scaleY(1);
				}
			}
		}
	}
	.language-mobile {
		display: none;
	}
	.nav-lang {
		text-transform: uppercase;
		font-size: 1.2rem;
		line-height: 1.5;
		padding: 0 15px;
		a {
			color: $color-text;
			font-weight: 500;
		}
		.icon-svg {
			width: 16px;
			height: 10px;
			fill: none;
			stroke: #000;
		}
		& + .dropdown {
			li {
				font-size: 1.4rem;
				background: $color-white;
				border-bottom: 1px solid $color-peacock-blue; 
				color: $color-peacock-blue; 
				&:hover {
					background: $color-powder-blue;
				}
			}
		}
	}
	.megamenu-toggle {
		padding-left: 15px;
	}
	.wr-menu-toggle {
		display: inline-block;
		position: relative;
		width: 30px;
		height: 20px;
		// margin-left: 15px; 
		span {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%; 
			height: 2px;
			border-radius: 9px;
			background: #000;
			transition: .3s all;
			transform-origin: center;
			&:first-child {
				bottom: auto;
				top: 0;
			}
			&:nth-child(2) {
				top: 50%;
				transform: translateY(-50%);
				bottom:105uto;
			}
		}
	}
	.has-megamenu {
		&.active {
			.wr-menu-toggle {
				text-align: center;
				span:first-child {
					top: 50%;
					bottom: auto;
					transform: rotate(45deg) translateY(-50%);
				}
				span:nth-child(2) {
					width: 0;
					opacity: 0;
				}
				span:last-child {
					bottom: 50%;
					top: auto;
					transform: rotate(-45deg) translateY(50%);
				}
			}
		}
	}
	.search {
		.icon-svg {
			vertical-align: middle;
			width: 18px;
			height: 18px;
		}
		span {
			vertical-align: middle;
			font-weight: 500;
		}
	}
	.guest {
		a {
			padding: 0 12.5px;
			font-size: 0;
			line-height: 0;
			@media (max-width: $screen-xs-min) {
				padding: 0; 
			}
		} 
		.icon-svg {
			margin-right: 5px;
			fill: none;
			width: 19px;
			height: 20px;
			stroke: #000;
		}
		span {
			display: inline-block;
			max-width: calc(100% - 25px);
			font-size: 12px;
			line-height: 1;
			font-weight: 500;
			word-break: break-word;
		}
	}
	.public,
	.extranet {
		a {
			padding: 0 5px;
			font-size: 0;
			line-height: 0;
		} 
		.icon-svg {
			fill: #000;
			width: 18px;
			height: 19px;
			margin-right: 5px;
			vertical-align: middle;
		}
		span {
			display: inline-block;
			max-width: calc(100% - 23px);
			font-size: 12px;
			line-height: 1;
			word-break: break-word;
			vertical-align: middle;
			font-weight: 500;
		}
	}
	.authorization {
        width: 106px;
		color: #000;
		a {
            width: 100%;
            max-width: 120px;
			text-align: center;
			font-size: 12px;
			line-height: 1.2;
			font-weight: 700;
			.icon-svg {
				width: 16px;
				height: 18px;
				margin-right: 5px;
				vertical-align: middle;
			}
			span {
				font-weight: 500;
			}
		}
	}
	.user-menu {
		a {
			font-size: 1.6rem;
			line-height: 1.4;
			font-weight: 700;
			text-align: center;
			max-width: 100%;
			color: $color-peacock-blue;
		}
	}
	.megamenu {
		position: absolute;
		padding: 80px 0; 
		top: 100%;
		width: 100%;
		height: 585px;
		left: 0;
		background: $color-primary;
		font-size: 1.6rem;
		line-height: 1.5;
		opacity: 0;
		visibility: hidden;
		transition: .4s all;
		pointer-events: none;
		z-index: -1; 
		&.open {
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
			z-index: 101; 
		}
		.level-1 {
			width: 35%;
			padding-right: 5%;
			display: inline-block;
			position: relative;
			a {
				display: block;
				padding: 15px 0;
				color: #fff;
				font-size: 20px;
				line-height: 1.5;
				border-bottom: 1px solid transparent;
				span {
					padding-bottom: 5px;
					&:hover {
						border-bottom: 1px solid #fff;
					}
				}
			}
			.level-2 {
				display: inline-block;
				position: absolute;
				top: 0;
				left: 100%;
				width: 180%;
				opacity: 0;
				pointer-events: none;
				visibility: hidden;
				transition: .3s all;
				height: 420px;
				column-count: 2;
				column-fill: auto;
				overflow: hidden;
				a {
					font-size: 16px;
					padding: 12.5px 0;
				}
				&.open {
					pointer-events: auto;
					opacity: 1;
					visibility: visible;
				}
			}
		}
		@media (max-width: $screen-sm-min) {
			height: auto;
			padding: 40px 0;
			.level-1 {
				width: 100%;
				padding: 0;
				display: block;
				overflow: hidden;
				font-size: 0;
				line-height: 0;
				li {
					overflow: hidden;
					a {
						padding: 5px 0;
						font-size: 16px;
					}
					&.language-mobile {
						font-size: 0;
						line-height: 0;
						li {
							display: inline-block;
							a {
								padding-right: 10px; 
							}
						}
					}
				}
				.level-2 {
					position: static;
					padding-left: 20px;
					height: auto;
					max-height: 0;
					opacity: 0;
					column-count: 1;
					column-fill: auto;
					transition: .3s all;
					&.open {
						opacity: 1;
						max-height: 800px;
					}
				}
			}
		}
	}
	.menu-search {
		.megamenu {
			background: $color-peacock-blue;
			label[for] {
				display: block;
				padding: 25px 0 40px; 
				font-size: 4rem;
				line-height: 1.25;
				color: #ffffff;
				font-weight: 300;
				text-align: center;
			}
			[class*='col-'] {
				float: none; 
				margin: 0 auto;
			}
			.form-search-header {
				input {
					background: none;
					border-color: transparent;
					border-bottom: 1px solid #f8f8f86e;
					color: #ffffff;
					&::placeholder {
						font-weight: 400;
						color: inherit;
						font-size: 16px;
						line-height: 1;
						transition: .3s all;
					}
					&:focus {
						border-bottom: 1px solid #ffffff;
						&::placeholder {
							font-size: 0;
						}
						& ~ button {
							.icon-svg {
								filter: drop-shadow(0 0 2px #fff);
							}
						}
					}
				}
			}
			.form-group {
				position: relative;
				margin-bottom: 0;
				button {
					position: absolute;
					right: 15px;
					top: 2px;
					appearance: none;
					background: none;
					border: none;
					box-shadow: none;
					.icon-svg {
						width: 27px;
						height: 27px;
						fill: #ffffff;
						transition: .3s all;
					}
				}
			}
		}
		.autocomplete-results {
			margin-top: -2px;
			li {
				a {
					display: block;
					padding: 15px; 
					background: #fff;
					color: $color-peacock-blue;
					font-weight: 500;
					&:hover {
						background: #4d82b9;
						color: #fff;
					}
				}
			}
		}
	}
	#drucke {
		padding: 0 10px; 
		& > div {
			position: relative;
			display: inline-block;
			vertical-align: middle;
		}
		& > span {
			display: inline-block;
			vertical-align: middle;
			font-weight: 500;
		}
		.icon-svg {
			width: 25px;
			height: 20px;
		}
		.count {
			position: absolute;
			right: 0;
			bottom: 0;
			padding: 0 3px;
			border-radius: 360px;
			color: #ffffff;
			font-size: 0.85em;
		}
	}
	.subscribers-mobile {
		display: none;
	}
}
	@media (max-width: $screen-sm-min) {
		.wr-nav-primary {
			padding: 0;
		}
		.wr-navigation {
			padding: 10px 0; 
			li {
                margin: 0 4px;
				a {
					font-size: 0;
					vertical-align: middle;
					&#drucke {
						padding: 0 2.5px;
						font-size: 1rem;
					}
				}
				& > *:first-child:before {
					content: none;
				}

			}
			.menu-search,
			.authorization {
                width: 34px;
				span {
					display: none;
				}
				a {
					padding: 0 5px;
					.icon-svg {
						margin: 0; 
						height: 20px;
					}
				}
				.icon-svg {
					margin: 0; 
				}
			}
			.public,
			.guest,
			.extranet {
                span {
                    font-size: 10px;
                }
			}

			.nav-lang {
				display: none;
			}
			.language-mobile {
				display: block;
			}
			.wr-menu-toggle {
				margin-left: 5px;
			}
			.subscribers-mobile {
				display: block;
			}
		}
	}
	@media (max-width: 425px) {
		.wr-navigation {
			.public,
			.guest,
			.extranet {
                span {
                    display: none;
                }
			}
		}
	}
	@media (max-width: $screen-xs-min) {
		.wr-header {
			.wr-brand {
				.claim-extranet {
					padding: 0;
					display: block;
				}
			}
			.wr-navigation {
				// padding: 10px 0; 
			}
		}
	}
