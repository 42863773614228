.wr-article-list {
	padding: 50px 0;
	color: $color-peacock-blue;
	h3,
	.title {
		color: inherit;
		text-align: center;
		font-size: 40px;
		font-weight: 300;
		margin-bottom: 50px;
	}

	.item {
		background-color: #ffffff;
		overflow: hidden;
		clear: both;
		display: block;
		margin-bottom: 30px;
		transition: .3s all;
		.picture {
			position: relative;
			margin-bottom: 15px;
			img {
				width: 100%;
				height: 100%;
			}

			.copyright {
				position: absolute;
				bottom: 15px;
				right: 15px;
				color: $color-white;
			}
			/* next element*/

			& ~ * {
				padding: 15px;
			}
			@media (min-width: $screen-sm-min) {
				float: left;
				margin-right: 20px;
				margin-bottom: 0;
				width: 45%;
			}
		}
		a {
			color: #333333;
		}
		.title {
			font-size: 18px;
			font-weight: 300;
			color: $color-peacock-blue;
			margin: 0 0 15px;
			text-align: left;
			font-weight: 600;
		}
		p {
			margin-top: 15px;
			color: $color-text;
		}

		&:hover {
			box-shadow: 0px 0px 10px #909090;
		}
	}
	@media (max-width: $screen-xs-min) {
		padding: 25px 0; 
		h3,
		.title {
			margin: 0 0 30px; 
			font-size: 2.275em;
		}
	}
}