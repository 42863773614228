
@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-Thin.ttf');
    src: url('../../fonts/FiraSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-ThinItalic.ttf');
    src: url('../../fonts/FiraSans-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-ExtraLight.ttf');
    src: url('../../fonts/FiraSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-ExtraLightItalic.ttf');
    src: url('../../fonts/FiraSans-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-Light.ttf');
    src: url('../../fonts/FiraSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-LightItalic.ttf');
    src: url('../../fonts/FiraSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-Regular.ttf');
    src: url('../../fonts/FiraSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-Medium.ttf');
    src: url('../../fonts/FiraSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-MediumItalic.ttf');
    src: url('../../fonts/FiraSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-SemiBold.ttf');
    src: url('../../fonts/FiraSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-SemiBoldItalic.ttf');
    src: url('../../fonts/FiraSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-Bold.ttf');
    src: url('../../fonts/FiraSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-BoldItalic.ttf');
    src: url('../../fonts/FiraSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-ExtraBold.ttf');
    src: url('../../fonts/FiraSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-ExtraBoldItalic.ttf');
    src: url('../../fonts/FiraSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-Black.ttf');
    src: url('../../fonts/FiraSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('../../fonts/FiraSans-BlackItalic.ttf');
    src: url('../../fonts/FiraSans-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
font-family: 'Fira Sans Book';
src: url('../../fonts/FiraSans-Book.otf');
src: url('../../fonts/FiraSans-Book.otf') format('otf'),
    url('../../fonts/FiraSans-Book.woff') format('woff');
font-style: normal;
font-weight: 400;
}

@font-face {
font-family: 'Fira Sans Book';
src: url('../../fonts/FiraSans-BookItalic.otf');
src: url('../../fonts/FiraSans-BookItalic.otf') format('otf'),
    url('../../fonts/FiraSans-BookItalic.woff') format('woff');
font-style: italic;
font-weight: 400;
}

/* ------------------------------------*\
    #keyframes
\*------------------------------------*/
@-webkit-keyframes bottom-fade-in {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
    opacity: 0.3;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes bottom-fade-in {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
    opacity: 0.3;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes left-fade-in {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateX(-25px);
            transform: translateX(-25px);
    opacity: 0.3;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes left-fade-in {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  50% {
    -webkit-transform: translateX(-25px);
            transform: translateX(-25px);
    opacity: 0.3;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

