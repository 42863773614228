// margin and padding shorthands
$space-prefixes : (
  py : padding,
  px : padding,
  pt : padding-top,
  pr : padding-right,
  pb : padding-bottom,
  pl : padding-left,
  my : margin,
  mx : margin,
  mt : margin-top,
  mr : margin-right,
  mb : margin-bottom,
  ml : margin-left,
) !default;
$space-values : (
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
) !default;
$breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 480px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 960px,
  // Extra large screen / wide desktop
  xl: 1280px
) !default;

// fallback rgba color or background
//ex: transp(bg, $color-1,$color-2, 0.7);
@mixin transp($type, $color, $bgcolor, $percentage){
	@if $type == color {
		color: mix($color,$bgcolor, $percentage*100%);
		color: rgba($color, $percentage);
	}
	@if $type == bg {
		background-color: mix($color,$bgcolor, $percentage*100%);
		background-color: rgba($color, $percentage);
	}
}
// $bgcolor {String} - hex color
// $opacity {Double (0 - 1) value} --Opacity for IE11 fallback
// $selector {String} - Image selector for blend props
@mixin blend-multiply($bgcolor, $fallback_color, $opacity, $selector) {
	@if $fallback_color {
		background-color: darken($fallback_color, 32%);
	} @else {
		background-color: initial;
	}
	#{$selector} {
		@content;
		opacity: $opacity;
	}
	@supports (mix-blend-mode: multiply) {
		background-color: $bgcolor;
		#{$selector} {
			opacity: 1;
			mix-blend-mode: multiply;
		}
	}
}

@mixin scrollbar() {
  ::-webkit-scrollbar { width: 5px; }
  ::-webkit-scrollbar-track { background: none; }
  ::-webkit-scrollbar-thumb { background: #898989; }
  ::-webkit-scrollbar-track-piece:start { background: transparent; }
  ::-webkit-scrollbar-track-piece:end { background: transparent; }
}

// main function definition
@mixin make-space($values, $prefixes, $breakpoints) {
  @each $breakpoint-name, $breakpoint-value in $breakpoints {
    // if xs value = 0, set it global without media queries
    @if($breakpoint-value == 0) {
      @each $attr-short, $attr-long in $prefixes {
        @each $value in $values {
          [class].#{$attr-short}-#{$breakpoint-name}-#{$value} {
            @if($attr-short == py or $attr-short == my ) {
                #{$attr-long}: #{$value}#{'rem'} 0;
              } @else if ($attr-short == px or $attr-short == mx ) {
                #{$attr-long}: 0 #{$value}#{'rem'};
              } @else {
                #{$attr-long}: #{$value}#{'rem'};
              }
          }
        }
      }
    }
    // breakpoint values that not equal to 0
    @else {
      @media screen and (min-width: $breakpoint-value) {
        @each $attr-short, $attr-long in $prefixes {
          @each $value in $values {
            [class].#{$attr-short}-#{$breakpoint-name}-#{$value} {
              @if($attr-short == py or $attr-short == my ) {
                  #{$attr-long}: #{$value}#{'rem'} 0;
                } @else {
                  #{$attr-long}: #{$value}#{'rem'};
                }
            }
          }
        }
      }
    }
  }
}

@include make-space($space-values, $space-prefixes, $breakpoints);

@mixin invert_bg($color) {
  color: $color;
  svg {
    fill: #{$color};
  }
  &:hover {
    background-color: #{$color};
    color: #fff;
    svg {
      fill: #fff;
    }
  }
}