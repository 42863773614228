/* ------------------------------------------------------------ *\
	#blocks
\* ------------------------------------------------------------ */
.tag {
		display: inline-block;
		color: #ffffff;
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 1px;
		&:hover {
			color: #ffffff;
		}
		padding: 7px;
		font-size: 12px;
		margin-right: 10px;
		&.orange {
			background-color: $color-orange;
		}
		&.gray {
			background-color: $color-gray-md;
		}
		&.magenta {
			background-color: $color-magenta;
		}
		&.blue {
			background-color: $color-peacock-blue;
		}
	}

.wr-referent {
	background-color: #e6e6e6;
	padding: 50px 0;


	.col-md-6, .col-md-3 {
		font-size: 21px;

		&:first-child {
			color: $color-peacock-blue;
			font-weight: 500;
		}

		strong {
			font-weight: 500;
			display: block;
		}
	}
}

.wr-meeting-box {
	background-color: $color-peacock-blue;
	padding: 30px 0;
	color: #ffffff;
	font-size: 20px;
	font-weight: 500;
	margin-top: 30px;

	.row {
		margin-bottom: 15px;

		&:last-child {
			margin-top: 75px;
		}
	}

	small {
		font-size: 16px;
		font-weight: 300;
	}

	.wr-btn {
		font-size: 16px;
		font-weight: 300;
		padding: 5px 15px;
		line-height: 30px;
		border-radius: 30px;
	}
	@media (max-width: $screen-sm-min) {
		text-align: center;
	}
}

.wr-logo-list {
	.title {
		padding: 50px 0;
		text-align: center;
		font-size: 2.4em;
		line-height: 1.5;
		font-weight: 300;
		color: $color-peacock-blue;
	}
	.logo {
		text-align: center;
		padding: 15px 0;
		img {
			width: 220px;
			height: 125px;
			object-fit: cover;
			object-position: center;
		}
	}
}
.wr-content-box-list {
	padding: 30px 0 75px;
	color: $color-peacock-blue;
	& ~ .title {
		font-size: 2.857em;
		line-height: 1.25;
		font-weight: 300;
		margin: 0 0 50px;
		text-align: center;
	}
	.teaser {
		font-size: 1.285em;
		font-weight: 500;
		margin-bottom: 30px;
		text-align: center;
		margin-bottom: 70px;
	}
	.row {
		margin-bottom: 50px;
	}
}
.wr-content-box {
	position: relative;
	height: 100%;
	min-height: 320px;
	margin-bottom: 15px;
	padding: 20px 15px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	border-top: 2px solid $color-powder-blue;
	border-bottom: 2px solid $color-powder-blue;
	transition: .3s all;
	text-align: left;
	.heading,
	.box-header {
		font-size: 0;
		text-align: justify;
		margin: 0 0 20px;
		span {
			display: inline-block;
			vertical-align: middle;
			font-size: 12px;
		}
        span.date {
            float: right;
            vertical-align:middle;
            line-height: 3;
        }
		&:after {
			content: '';
			display: inline-block;;
			width: 100%;
		}
	}

	&:hover {
		border-top-color: $color-peacock-blue;
		border-bottom-color: $color-peacock-blue;
		box-shadow: 0px 0px 10px #909090;
	}

	.title {
		font-size: 18px;
		font-weight: 500;
		color: $color-peacock-blue;
		margin: 0; 
		display: block;
		line-height: 25px;
		text-align: left;
	}

	p {
		line-height: 1.8;
		color: $color-text;
	}

	strong {
		display: block;
	}


	&.event {
		// justify-content: space-between;
		min-height: 260px;
		.title {
			margin-bottom: auto;
		}
		p {
			margin: 0;
		}

		.event-info {
			color: $color-text;
			padding: 20px 0;
			.location,
			.time,
			.date {
				.info-label {
					font-size: 0.857em;
				}
				p,
				strong {
					font-size: 1.125em;
				}
				strong {
					line-height: 1.8;
					padding: 5px 0;
				}
			}
		}
		.wr-btn {
			font-size: 16px;
			padding: 5px 20px;
		}
	}
}
.wr-overview-list {
	.row {
		display: block;
		flex-flow: row wrap;
		padding: 50px 0;

		@media (min-width: $screen-sm-min) {
			display: flex;
		}
		@media (max-width: $screen-sm-min) {
			margin: 0;
		}

	}
	[class*="col"] {
		padding-bottom: 15px;
		border: 1px solid #fff;
	}

	&:before {
		display: block;
		content: none;
	}
}
.publications-list {
	margin: 0 -10px;  
	[class*='col'] {
		padding: 10px;
	}

	.wr-publication-download {
		display: block;
		height: 445px;
		overflow: hidden;
		text-align: center;
		transition: .4s all;
		background: $color-gray-lt;
		.overlay {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			text-align: center;
			fill: #ffffff;
			color: #ffffff;
			transform: translateY(-50%);
			opacity: 0;
			z-index: 1; 
			.icon-svg {
				width: 50px;
				height: 75px;
				margin-bottom: 15px;
				& + p {
					font-size: 1.285em;
				}
			}
		}
		figure {
			@include blend-multiply(unset, null, 0.25, '.publication-image');
			display: inline-block;
			position: relative;
			transition: .3s all;
			background-color: none;
			.publication-image {
				max-width: 100%;
				opacity: 1;
			}
			&:hover {
				background-color: darken(#0000ff, 25%);
				.publication-image {
					opacity: 0.25;
				}
				@supports (mix-blend-mode: multiply) {
					.publication-image {
						opacity: 1;
					}
					background-color: $color-peacock-blue;
				}
				.overlay {
					opacity: 1;
				}
			}
		}
		a {
			display: block;
		}
		.publication-title {
			padding: 10px 10px 0;
			font-size: 0.9em;
			line-height: 1.5;
			font-weight: 500;
			text-align: left;
		}
		&:hover {
			box-shadow: 0px 0px 10px #909090;
		}
		@media screen and (min-width: $screen-xs-min) {
			text-align: center;
			.publication-title {
			}
			&:hover {
				box-shadow: 0px 0px 10px #909090;
			}
		}
		@media screen and (max-width: $screen-sm-max) {
			max-width: 220px;
			margin: 0 auto;
		}
	}

	@media screen and (min-width: $screen-md-min) {
		display: flex;
		justify-content: center;
	}
}

.wr-page-header {
	text-align: center;
	color: $color-peacock-blue;
	padding-bottom: 50px;
	margin-bottom: 50px;
	.title {
		font-size: 3.715em;
		line-height: 1.25;
		font-weight: 300;
		margin: 0 0 25px;
	}
	.teaser {
		font-size: 1.428em;
		line-height: 1.3;
		font-weight: 500;
		margin: 0;
		padding: 25px 0 50px;
	}
	&.border {
		border-bottom: 1px solid $color-peacock-blue;
	}
	@media (max-width: $screen-xs-min) {
		padding-bottom: 20px;
		margin-bottom: 20px;
		font-size: 1.2rem;
		font-size: 2.4vmin;
		.teaser {
			padding: 15px 0 25px;
		}
		.title {
			margin: 0 0 15px;
		}
	}
}
.wr-print-list {
	.wr-content-box {
		display: block;
		height: auto;
		.content {
			padding: 30px 0;
			display: inline-block;
			vertical-align: middle;
		}
		.drucken-actions {
			text-align: center;
			font-size: 0; line-height: 0;
			li {
				display: inline-block;
				padding: 0 10px;
			}
			a,span {
				display: block;
				font-size: 24px;
				padding: 2.5px 0;
				text-align: center;
				color: $color-peacock-blue;
				&:before {
					margin: 0;
				}
				&:not(.wr-wf-pdf) {
					&:before {
						font-weight: 500;
					}
				}
			}
		}

		@media screen and (min-width: $screen-xs-min) {
			.drucken-actions {
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
				li {
					display: block;
					padding: 5px 0;
				}
			}
			.content {
				max-width: calc(100% - 50px);
			}
		}
		@media (max-width: $screen-sm-min) {
			height: auto;
			.content {
				padding: 10px 0;
			}
		}
	}
}

.wr-newsletter-hero {
	height: auto;
	overflow: hidden;

	h1 {
		font-size: 32px;
	}
	p {
		font-size: 16px;
	}
	img {
		width: 100%;
   	 	object-fit: cover;
	}
	@media (min-width: $screen-lg-min) {
		height: 582px;
	}
	@media (min-width: $screen-xs-min) {
		h1 {
			font-size: 52px;
		}
		p {
			font-size: 24px;
		}
	}
}

.wr-newsletter {
	color: $color-peacock-blue;
	padding: 0;
	.main-heading {
		padding-bottom: 50px;
		border-bottom: 1px solid $color-peacock-blue;
		text-align: center;
		h1 {
			margin-bottom: 50px;
			font-size: 52px;
		}
		p {
			font-size: 24px;
		}
		@media (min-width: $screen-xs-min) {
			.container {
				padding: 250px 0 50px 0px;
			}
			h1 {
				font-size: 52px;
			}
			p {
				font-size: 20px;
				line-height: 26px;
			}

		}
	}
	@media (min-width: $screen-sm-min) {
		.main-heading {
			padding-bottom: 100px;
		}
	}
}

.ext-hero-title {
	figure {
		position: relative;
		figcaption {
			position: absolute;
			bottom: 10px;
			right: 10px;
			color: #fff;
		}
	}
	img {
		width: 100%;
		height: auto;
		max-height: 582px;
		object-fit: cover;
		object-position: top;
	}
}
.wr-page-image {
	margin-bottom: 50px;
	text-align: center;
	figure {
		max-width: 100%;
		position: relative;
		display: inline-block;
	}
	img {
		max-width: 100%;
		display: block;
	}
	figcaption {
		position: absolute;
		right: 10px;
		bottom: 10px;
		color: #ffffff;
		text-align: left;
	}

}
.wr-ext-contents {
	padding: 20px 0;
	[class*='col-'] > .title {
		margin: 0;
		text-align: center;
		font-size: 2.285em;
		color: $color-peacock-blue;
	}
	.wr-content-box {
		margin-bottom: 30px;
		min-height: 265px;
	}
}

.wr-bodytext {
	font-size: 18px;
	line-height: 30px;
	font-family: $font-secondary;
	color: $color-text;
	@media (max-width: $screen-xs-min) {
		font-size: 16px;
		line-height: 1.5;
	}
}

.wr-comment-section {
	max-width: 780px;
	margin: 70px auto;
	text-align: left;
	color: #333333;
	padding: 0 10px; 
	.comments-level-1 {
		& > li {
			margin-bottom: 30px;
		}
	}
	.comments-level-2 {
		padding-left: 80px;
		.comment {
			margin-bottom: 25px;
		}
	}
	.comment-count {
		margin-bottom: 50px;
		font-size: 20px;
		font-weight: 500;
		line-height: 26px;
	}
	.comment-header {
		margin-bottom: 5px;
		.author {
			margin: 0;
			color: $color-peacock-blue;
			font-size: 16px;
			line-height: 24px;
			font-weight: 600;
		}
		.date {
			font-size: 12px;
			line-height: 18px;
		}
	}
	.comment-body {
		p {
			font-size: 16px;
			line-height: 24px;
		}
	}
	.comment-footer {
		.wr-btns {
			text-align: right;
			.wr-btn {
				padding: 2px 13px;
				font-size: 16px;
				line-height: 24px;
				font-weight: 400;
				&:before {
					font-size: 15px;
				}
			}
		}
	}
}
.wr-event-timeline {
	color: $color-text;
	margin-bottom: 30px;
	.schedule-row {
		font-size: 16px;
		padding: 25px 0;
		.time {
			font-size: 20px;
			line-height: 1;
			font-weight: 700;
			color: $color-peacock-blue;
		}
		.details {
			h2,h3,h4,h5,h6 {
				color: $color-peacock-blue;
				font-size: 20px;
				margin: 0 0 20px;
				& + p {
					font-weight: 500;
				}
			}
		}
	}
	.information-row {
		font-size: 16px;
		.info-label {
			font-weight: 600;
		}
		.details {
			p:first-child {
				font-weight: 500;
			}
		}
	}
}
.wr-stats {
	padding: 25px 0 50px; 
	.title {
		color: $color-peacock-blue;
		margin: 0 0 50px;
	}
	 [class*="col-"] {
	 	&:first-child {
	 		.chart-container {
		 		border: none;
	 		}
	 	}
	 }
}
.chart-container {
	padding: 0 15px;
	margin-bottom: 20px;
	.chart-title {
		margin-bottom: 15px;
		overflow: hidden;
		font-size: 18px;
		color: $color-peacock-blue;
		font-weight: 600;
		text-align: center;
	}

	@media (max-width: $screen-sm-min) {
		margin-bottom: 35px;
		.chart-title {
			height: auto;
		}
	}
}