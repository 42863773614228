.wr-title-teaser {
	color: $color-peacock-blue;
	text-align: center;
	padding: 30px 0 70px;
	.title {
		font-size: 2.85em;
		line-height: 1.5;
		font-weight: 300
	}
	.teaser {
		font-size: 1.45em;
		line-height: 1.5;
		font-weight: 500;
	}
	.page-404 {
		.title,
		.teaser {
			margin-bottom: 75px;
		}
		.wr-btn{
			margin: 0 25px; 
		}
		margin-bottom: 250px;
	}
	@media (max-width: $screen-sm-min) {
		padding: 15px 0 25px;
		.title {
			font-size: 2.275em;
			margin: 0 0 15px; 
		}
		.teaser {
			font-size: 1.275em;
			margin: 0 0 15px;
		}
	}
}