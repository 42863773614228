.wr-autocomplete-results {
	padding: 0 40px; 
	ul {
		padding: 0;
		margin: 0; 
	}
	.wr-autocomplete-item {
			display: block;
			padding: 0 20px; 
			font-size: 1.625em;
			line-height: 1.7;
			text-decoration: none;
			color: $color-text;
			&:hover {
				background: $color-gray-lt;
				color: #ffffff;
			}
	}
}