.wr-twitter-slider {
	padding: 65px 0;
	fill: $color-peacock-blue;
	color: $color-peacock-blue;
	.twitter-channel {
		font-size: 3.2rem;
		line-height: 1.35;
		font-weight: 300;
		vertical-align: middle;
		margin-bottom: 50px;
		color: inherit;
		text-align: center;
		.icon-svg {
			fill: inherit;
			width: 35px;
			height: 35px;
			margin-right: 10px;
			vertical-align: middle;
		}
	}
	.twitter-slider {
		width: 100%;
		margin: 0 auto;
		margin-bottom: 50px;
		text-align: center;
		.twitter-slide {
			text-align: center;
			width: 620px;
			max-width: 620px;
			margin: 0 auto;
			.twitter-content {
				font-size: 1.8rem;
				line-height: 2;
				font-weight: 300;
				color: $color-text;
			}
		}
		@media (min-width: $screen-sm-min) {
			width: calc(100% - 160px);
		}
	}
	.slick-arrow {
		height: 70px;
		width: 35px; 
		fill: inherit;
		&.slick-prev {
			left: 0; 
		}
		&.slick-next {
			right: 0;
		}
	}
	a {
		color: inherit;
	}
}