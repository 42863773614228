.wr-text-accent-portlet {
	padding: 70px;
	background-color: $color-peacock-blue;
	color: #ffffff;

	h3 {
		font-size: 32px;
		font-weight: 200;
	}

	p {
		font-size: 18px;
		margin: 50px 0;
	}

	@media (max-width: $screen-xs-min) {
		padding: 15px; 
	}
}