.wr-modal {
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	padding: 25px; 
	background-color: #ffffff;
	z-index: 200; 
	#modal_close {
		position: absolute;
		right: 5px;
		top: 5px;
		padding: 5px; 
		color: $color-peacock-blue;
		opacity: 1;
		z-index: 20;
		cursor: pointer;
		font-size: 24px;
		line-height: 1;
	}
	&.active {
		display: block;
		 & ~ .js-container {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				opacity: 0.5;
				background-color: $color-peacock-blue;
			}
		}
	}
	&[data-type="member"] {
		.print-list-modal {
			display: none;
		}
	}
	&[data-type="send_email"] {
		.member-modal {
			display: none;
		}
	}
	.member-modal {
		.member-info {
			margin-bottom: 15px;
			.information {
				display: inline-block;
				vertical-align: middle;
				padding-left: 15px;
				max-width: calc(100% - 150px);
				.first-name,
				.last-name {
					font-size: 20px;
					font-weight: 700;
					color: $color-peacock-blue;
					margin-bottom: 5px;
				}
				.last-name {
					margin-bottom: 20px;
				}
				.position,
				.company {
					font-size: 16px;
					margin-bottom: 5px;
				}
				.company {
					margin-bottom: 20px;
				}
				.info {
					font-size: 16px;
					display: block;
					margin-bottom: 5px;
					vertical-align: middle;
					color: $color-gray-md;
					&:before {
						color: $color-text;
						margin-right: 5px;
						vertical-align: middle;
					}
				}
			}
		}
		.picture {
			display: inline-block;
			vertical-align: middle;
			max-width: 150px;
			img {
				width: 150px;
				height: 200px;
				object-fit: cover;
				object-position: top; 
			}
		}
		.additional-info {
			column-count: 2;
			column-fill: auto;
			-moz-column-fill: auto;
			-moz-column-count: 2;

			-moz-column-gap: 50px;
			-webkit-column-gap: 50px;

			min-height: 100px;
			li.info {
				font-size: 0;
				line-height: 0;
				// padding: 0 25px 0;
				margin-bottom: 10px;
				font-size: 16px;
				.info-label,
				.value {
					vertical-align: top;
					line-height: 1.5;
					display: inline-block;
				}
				.value {
					max-width: 175px;
					margin-left: 10px;
					font-weight: 700;
				}
			}
	        @media (max-width: map-get($breakpoints, "xsm")) {
	            column-count: 1;
	        }
		}
	}

	@media (max-width: map-get($breakpoints, "xsm")) {  // 479px
	    width: 100%;
	    height: 100%;
	    overflow-y: auto;
	}

	@media (min-width: $screen-xs-min) {
        min-width: 379px;
        min-height: 379px;
	}
}
