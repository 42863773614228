// general settings
$wrapper-width: 1280px;
$container-width: 940px;

// colors
$color-primary: #00274e;  // #navi blue
$color-secondary: #0f3851;
$color-gray-lt: #e6e6e6;
$color-gray-md: #b4b6bf;
$color-gray-dk: #666666;
$color-gray-blue: #80a6cd;
$color-brownish-gray: #666666;
$color-navy-blue: #00274e;
$color-peacock-blue: #004d9b;
$color-faded-blue: #6694c3;
$color-powder-blue: #ccdbeb;
$color-orange: #ff6600;
$color-magenta: #d900b3;
$color-error: #ff9494;
$color-success: #54c46a;

$color-white: #ffffff;
$color-white-md: #f5f5f5;
$color-text: #333333;

// fonts and font-styles
$font-primary: 'Fira Sans', sans-serif;
$font-secondary: 'Fira Sans Book', sans-serif;