.wr-cookiebanner {
	display: none;
	position: fixed;
	bottom: 0;
	width: 100%; 
	z-index: 50;
	.container {
		flex-wrap: wrap;
	}

	.wr-cookie-content {
		padding: 20px 0; 
		font-weight: 300;
		background: $color-peacock-blue;
		color: #fff;
		.title {
			display: block;
			font-size: 1.145em;
		}
		.wr-cookie-text {
			margin-bottom: 15px;
			p {
				font-size: 0.85em;
				line-height: 1.75;
				margin-bottom: 25px;
			}
			a {
				color: inherit;
				font-weight: 400;
				font-size: 1.145em;
				&:hover{
					text-decoration: underline;
				}
			}
		}
		.wr-btns {
			.wr-btn {
				margin-bottom: 20px; 
				padding: 7.5px 20px; 
				color: #fff;
				font-size: 1.285em;
			}
			a#wr__cookiebanner_close {
				color: #000;
			}
		}
	}
	@media print {
		display: none !important; 
	}
}
.bvv-cookie-popup .cookie-container .cookie-heading .bvv-checkbox:after {
    content: '';
    position: absolute;
    right: 9px;
    top: 3px;
    width: 8px;
    height: 15px;
    transform: rotate(45deg);
    border: solid #004d9b;
    border-width: 0 3px 3px 0;
    opacity: 0;
}
.cookies-floating{
	z-index: 1;
}

.bvv-cookie-popup .cookie-container .btn-primary {
     margin-bottom: 20px;
}
.bvv-cookie-popup .cookie-container .btn-primary.accept-all {
     display: block;
     width: 45%;
     font-size: 16px;
     background: #004d9b;
     border: none;
     float: left;
     margin-right: 15px;
}
.bvv-cookie-popup .cookie-container .link.settings {
    display: block;
    width: 45%;
    font-size: 16px;
    background: #004d9b;
    border: none;
    float: left;
    margin-right: 15px;
    color: white;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    padding: 6px 12px;
    line-height: 1.42857;
    border-radius: 4px;
}
 .bvv-cookie-popup .cookie-container .link {
    display: inline-block;
}

@media(max-width: 768px){
     .bvv-cookie-popup .cookie-container .btn-primary.accept-all {
          font-size: 11px;
          width: 42%;
     }
}
@media(max-width: 768px){
     .bvv-cookie-popup .cookie-container .link.settings {
          font-size: 11px;
          width: 42%;
     }
}
.cookie-btn {
    background: transparent;
    border: 0;
    cursor: pointer;
}

