/* ------------------------------------------------------------ *\
	#portlets
\* ------------------------------------------------------------ */
@import '_wr_banner_portlet.scss';
@import '_wr_contacts_portlet.scss';
@import '_wr_richtext_portlet.scss';
@import '_wr_quote_portlet.scss';
// @import '_wr_ihr_kontakt_portlet.scss';
@import '_wr_in_focus_portlet.scss';
@import '_wr_text_accent_portlet.scss';
@import '_wr_article_list.scss';
@import '_wr_people_slider.scss';
@import '_wr_hero_portlet.scss';
@import '_wr_highlight_topic_slider.scss';
@import '_wr_topic_slider.scss';
@import '_wr_twitter_slider.scss';
@import '_wr_association_detail.scss';
@import '_wr_region_list.scss';
@import '_wr_title_teaser.scss';
@import '_wr_video_slider.scss';
@import '_wr_video_embed.scss';
@import '_wr_images_download_portlet.scss';
@import '_wr_image_slider_portlet.scss';
@import '_wr_publications_download.scss';
@import '_wr_chat_portlet.scss';




/* ------------------------------------------------------------ *\
	#portlets generics
\* ------------------------------------------------------------ */
.wr-portlet {
	.wr-btn,
	.slick-arrow {
		border-color: inherit;
		color: inherit;
		fill: inherit;
	}
}