.wr-video-embed-portlet {
	padding: 70px 0;
	text-align: center;
	.title {
		margin-top: 0;
		margin-bottom: 30px;
		font-size: 2.85em;
		line-height: 1.5;
		font-weight: 300;
	}
	.teaser {
		font-size: 1.285em;
		font-weight: 500;
		margin-bottom: 30px;
	}
	iframe {
		margin-bottom: 50px;
	
	}
	.btns {
		text-align: center;
	}
	@media (max-width: $screen-md-min) {
		iframe{
			width: 100%;

		}
	}
	@media (max-width: $screen-xs-min) {
		.title {
			font-size: 1.75em;
			margin-bottom: 15px;
		}
		.teaser {
			font-size: 1em;
		}
	}
}
