@import "_wr_iconfont_variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?mfxs64');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?mfxs64#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?mfxs64') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?mfxs64') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?mfxs64##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="wr-wf-"], [class*=" wr-wf-"] {
  &:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: middle;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.wr-wf-add-icon {
  &:before {
    content: $wr-wf-add-icon; 
    margin-right: 10px;
  }
}
.wr-wf-presse-news {
  &:before {
    content: $wr-wf-presse-news; 
  }
}
.wr-wf-member-1 {
  &:before {
    content: $wr-wf-member-1; 
  }
}
.wr-wf-member-2 {
  &:before {
    content: $wr-wf-member-2; 
  }
}
.wr-wf-presse {
  &:before {
    content: $wr-wf-presse; 
  }
}
.wr-wf-contacts {
  &:before {
    content: $wr-wf-contacts; 
  }
}
.wr-wf-profile {
  &:before {
    content: $wr-wf-profile; 
  }
}
.wr-wf-linkedin {
  &:before {
    content: $wr-wf-linkedin; 
  }
}
.wr-wf-twitter {
  &:before {
    content: $wr-wf-twitter; 
  }
}
.wr-wf-youtube {
  &:before {
    content: $wr-wf-youtube; 
  }
}
.wr-wf-button-arrow {
  &:before {
    content: $wr-wf-button-arrow;
    margin-right: 10px;
  }
  margin-bottom: 10px;
}
.wr-wf-calendar {
  &:before {
    content: $wr-wf-calendar; 
  }
}
.wr-wf-close {
  &:before {
    content: $wr-wf-close; 
  }
}
.wr-wf-contact {
  &:before {
    content: $wr-wf-contact; 
  }
}
.wr-wf-document {
  &:before {
    content: $wr-wf-document; 
    margin-right: 10px;
    font-size: 1.2em;
  }
}
.wr-wf-download {
  &:before {
    content: $wr-wf-download; 
  }
}
.wr-wf-facebook {
  &:before {
    content: $wr-wf-facebook; 
  }
}
.wr-wf-label {
  &:before {
    content: $wr-wf-label; 
  }
}
.wr-wf-link {
  &:before {
    content: $wr-wf-link; 
    margin-right: 10px;
    font-size: 1.2em;
  }
}
.wr-wf-mail {
  &:before {
    content: $wr-wf-mail; 
  }
}
.wr-wf-mail-outline {
  &:before {
    content: $wr-wf-mail-outline; 
  }
}
.wr-wf-pdf {
  &:before {
    content: $wr-wf-pdf; 
    margin-right: 10px;
    font-size: 1.5em;
  }
}
.wr-wf-phone {
  &:before {
    content: $wr-wf-phone; 
  }
}
.wr-wf-location {
  &:before {
    content: $wr-wf-location; 
  }
}
.wr-wf-print {
  &:before {
    content: $wr-wf-print; 
  }
}
.wr-wf-arrow-left {
  &:before {
    content: $wr-wf-arrow-left; 
  }
}
.wr-wf-arrow-right {
  &:before {
    content: $wr-wf-arrow-right; 
  }
}

