.wr-article {
	font-size: 20px;
	color : $color-peacock-blue;
	.wr-article-header {
		.row {
			margin: 15px 0;
			.date {
				display: inline-block;
				color: #333333;
				font-size: 12px;
				font-weight: 500;
				padding-top: 0.75em;
			}
			&.pdf {
				text-align: right;
			}
			@media(max-width: $screen-sm-min) {
				margin: 10px 0;
				&.pdf {
					text-align: left;
				}
			}
		}
	}

	.title {
		font-size: 40px;
		font-weight: 300;
	}

	.subtitle {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 25px;
	}
	.wr-article-summary {
		color: $color-text;
		margin-bottom: 15px;
	}
	.wr-article-pdf {
		display: inline-block;
		font-size: 16px;
		&:before {
			font-size: 1.875em;
		}
	}

	.wr-article-image {
		position: relative;
		margin-bottom: 20px;
		img {
			width: 100%;
			height: auto;
		}
		figcaption {
			position: absolute;
			bottom: 20px;
			right: 20px;
			font-size: 1rem;
			color: #ffffff;
		}

	}

	.wr-article-inner {
		max-width: 620px;
		padding: 50px 0; 
		margin: 0 auto;
		color: $color-text;
		font-size: 0.9em;
		font-family: $font-secondary;
		.author {
			font-size: 12px;
			font-weight: 500;
			padding-bottom: 20px;
		}
		figure {
			position: relative;
			figcaption {
				position: absolute;
				bottom: 20px;
				right: 20px;
				font-size: 1rem;
				color: #ffffff;
			}
		}
		.responsive-object {
			padding: 0 0 15px !important; 
		}
		img {
			max-width: 100%;
		}
		p {
			margin-bottom: 20px;
			line-height: 1.65;
		}
		@media (max-width: $screen-xs-min) {
			padding: 20px 0;
		}
	}

	.wr-article-footer {
		margin-bottom: 50px;
		.wr-article-pdf {
			display: block;
			font-size: 21px;
			margin-bottom: 25px;
		}
		.downloads {
			padding-top: 30px; 
			.title {
				font-size: 2.4rem;
				font-weight: 500;
				margin-bottom: 25px;
				color: $color-peacock-blue;
			}
			ul {
				font-weight: 300;
				color: $color-peacock-blue;
				li {
					margin-bottom: 25px;
					a {
						display: inline-block;
						&:before {
							display: inline-block;
							text-decoration: none;
							white-space: pre-wrap;
							vertical-align: middle;
							font-size: 1.5em;
						}
						&:hover {
							text-decoration: underline;
							&:before {
								text-decoration: none;
							}
						}
					}
				}
			}
		}
	}
	@media (max-width: $screen-sm-min) {
		font-size: 16px;
	}
	@media(max-width: $screen-sm-min) {
		.title {
			font-size: 3.2rem;
			margin: 0 0 15px;
		}
		.subtitle {
			font-size: 1.6rem;
			margin: 0 0 15px; 
		}
	}

}