/* ------------------------------------------------------------ *\
	#filters
\* ------------------------------------------------------------ */

.wr-filters {
	margin-bottom: 30px;
	color: $color-peacock-blue;
	font-size: 1.2875em;
	line-height: 1.5;
	> .title {
		font-size: 2.85em;
	}
	.wr-content-list-filters {
		margin-bottom: 35px;
	}
	.wr-search {
		min-width: 250px;
		position: relative;
		.autocomplete-results {
			width: 100%; 
			position: absolute;
			z-index: 105;
			li {
				padding: 5px; 
				background: #fff;
				&:hover {
					background: $color-powder-blue;
				}
			}
		}
		@media (min-width: $screen-xs-min) {
			&.content-list-search {
				float: right;
			}
		}
		@media (max-width: $screen-md-min) {
			&.content-list-search {
				float: left;
			}
		}
		@media (max-width: $screen-xs-min) {
			&.content-list-search {
				float: none;
			}
		}
	}
	.filters-row {
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid $color-peacock-blue; 
	}
	.filters {
		font-size: 0;
		float: left;
		select {
			border-left: none;
		}
		.wr-select:first-child {
			select {
				border-left: 1px solid $color-peacock-blue; 
			}
		}
	}
	.controls {
		float: right;
	}
	.content-filter {
		float: right;
	}
	.wr-select {
		display: inline-block;
		font-size: 1.8rem;
		line-height: 1.5;
		font-weight: 700;
	}
	.results {
		float: left;
		margin-bottom: 0;
		padding: 10px 0; 
		strong {
			padding: 0 15px;
		}
	}
	.select-sorting {
		float: right;
		label {
			padding-right: 10px;
		}
	}
	.clear-filters {
		font-size: 1.8rem;
		line-height: 1.5;
		font-weight: 500;
		margin-right: 10px;
		&:before{
			font-weight: 900;
			margin-right: 10px;
		};
	}

	.sorting {
		.wr-paginator {
			padding: 0;
			text-align: left;
		}
		.pagination {
			margin: 0;
		}
	}
	@media (max-width: $screen-md-min) {
		margin-bottom: 15px;
		.wr-select {
			display: block;
			select {
				border: 1px solid $color-peacock-blue;
			}
		}
		.controls {
			text-align: right;
		}
		.clear-filters {
			display: block;
			margin-bottom: 10px;
		}
		.select-sorting,
		.results {
			float: none;
			display: inline-block;
			margin-bottom: 5px;
			margin: 0 auto 10px;
			strong {
				padding-left: 0;
			}
		}
		.content-filter {
			& > label {
				display: block;
			}
		}
	}
	@media (max-width: $screen-sm-min) {
		padding: 0 15px; 
		> .title {
			font-size: 3.2rem;
		}
		.results {
			display: block;
		}
		.content-filter {

			display: inline-block;
			margin-bottom: 10px;
			float: none;
			& > label {
				display: block;
			}
		}
	}
	@media (max-width: $screen-xs-min) {
		.results {
			display: block;
			float: none;
		}
		.filters {
			float: none;
			margin-bottom: 15px;
		}
		.controls {
			display: block;
			float: none;
			text-align: left;
			.clear-filters {
				display: inline-block;
			}
			.wr-btn {
				display: inline-block;
			}
		}
	}
}