.wr-in-focus-portlet {
	// background-color: #e6e6e6;
	padding: 70px 0 75px 0;	
	text-align: center;
	color: $color-peacock-blue;

	& > .title {
		margin: 0 0 50px;
		// font-size: 2.857em;
		line-height: 1.5;
		font-weight: 300;
	}
	.teaser {
		font-size: 1.428em;
		font-weight: 500;
		margin: 0 0 70px;
	}

	.row {
		margin-bottom: 50px;
		&::before {
			display: block;
		}
		@media (min-width: $screen-md-min) {
			display: flex;
			flex-flow: row wrap;
		}
	}

	.col-md-4 {
		padding-right: 10px;
		padding-left: 10px;

		.wr-content-box {
			background-color: #ffffff;
			display: flex;
			flex-direction: column;


			strong {
				display: block;
			}


			&.event {
				justify-content: space-between;
				.title {
					margin-bottom: auto;
				}
				p {
					margin: 0;
				}
			}
		}


	}

	.wr-btn {
		margin: 20px auto;
		padding: 0 20px;
		line-height: 45px;
		display: inline-block;
		color: inherit;
		fill: inherit;
		border-color: inherit;
	}
	@media (max-width: $screen-xs-min) {
		padding: 25px 0; 
	}
}