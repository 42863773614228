.wr-chat-portlet {
    margin-top: 20px;
    margin-bottom: 20px;
    .chatbox {
        border: 1px solid #E0E0E3;
        border-radius: 20px;
        margin: 20px 5px 20px 5px;
        .chat-header {
            background-color: #194D9C;
            border-radius: 20px 20px 0 0;
            margin: 0 -10px 0 -10px;
            h3 {
                color: #fff;
                font-size: 16px;
                font-weight: 600;
                padding: 10px 20px;
            }
        }
        .comment-box {
            padding: 10px 20px;
            div.container {
                width: 100%;
            }
            overflow-y: scroll;
            height: 400px;
        }
    }
    @media screen and (max-width: 950px) {
        margin: 0 20px 0 20px;
    
    }
    .chat-portlet-error-message {
        display: none;
    }
}
