.wr-publications-download-portlet {
	padding: 70px 0;
	text-align: center;
	color: $color-peacock-blue;
	.title {
		margin: 0 0 50px;
		font-size: 2.857em;
		line-height: 1.25;
		font-weight: 300;
	}
	.teaser {
		margin-bottom: 50px;
		font-size: 1.4285em;
		line-height: 1.25;
		font-weight: 500;
	}

	@media (max-width: $screen-xs-min) {
		padding: 25px 0; 
		.title {
			font-size: 2.275em;
			margin: 0 0 15px;
		}
		.teaser {
			font-size: 1.275em;
		}
	}
}