/* ======================= #breadrumbs ======================= */
.wr-breadcrumbs {
	padding: 10px 0px;
	text-align: left;
	ul {
		padding: 0; 
		list-style: none;
		font-size: 0;
		line-height: 0;
		margin-bottom: 0;
		li {
			display: inline-block;
			
			a, span {
				display: block;
				color: #333333;
				font-size: 12px;
				line-height: 18px;
				padding: 5px 0;
				text-decoration: none;
				&:after {
					content: ">";
					padding: 0 5px; 
				}
				@media (max-width: $screen-xs-min) {
					padding: 5px 0; 
				}
			}
			&:last-child {
				a, span {
					// pointer-events: none;
					cursor: auto;
					&:after {
						content: none;
					}
				}
			}
		}
	}

	@media print {
		display: none !important;
	}
	@media (max-width: $screen-xs-min) {
		ul > li > a,span {
			padding: 5px 0; 
		}
		padding: 5px 0;
	}
}
