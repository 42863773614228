.wr-people-slider {
	padding: 70px 0;

	.title {
		font-size: 4rem;
		line-height: 1.25;
		color: $color-peacock-blue;
		font-weight: 300;
		text-align: center;
		margin-bottom: 50px;
	}
	@media (max-width: $screen-xs-min) {
		padding: 25px 0;
		.title {
			margin: 0 0 15px;
			font-size: 2.275em;
			margin-bottom: 25px;
		}
		.teaser {
			margin: 0 0 15px;
			font-size: 1.275em;
		}
	}
}

.wr-boxes-people {
	font-size: 0;
	line-height: 0;
	margin: 0 -10px; 
	.wr-box-people {
		position: relative;
		height: 415px;
		width: 300px;
		min-width: 300px;
		max-width: 300px;
		padding: 20px 15px;
		margin: 0 10px;
		border: solid transparent;
		border-width: 2px 0;
		display: inline-block;
		font-size: 1rem;
		line-height: 1.5;
		background: #fff;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			box-shadow: 0 0 15px #cbcbcb;
			z-index: -1;
			transition: .3s opacity;
		}
		.person-info {
			text-align: left;
			margin-bottom: 20px;
			.picture {
				float: left;
				margin-right: 10px;
			}

			.first-name,
			.last-name { 
				font-size: 1.6rem;
				line-height: 1.5;
				color: $color-peacock-blue;
				font-weight: 700;
				margin: 0; 
			}
		}
		.person-quote {
			font-style: italic;
			font-weight: 300;
			text-align: center;
			font-size: 1.6rem;
			line-height: 1.75;
			height: 7em;
			overflow: hidden;
			margin-bottom: 10px;
			color: $color-text;
		}
		.person-occupation {
			text-align: left;
			max-height: 12em;
			overflow: hidden;
			color: $color-text;
			p {
				margin: 0;
				font-size: 1.2rem;
				line-height: 1.5;
			}
		}
	}
	&.slick-initialized {
		text-align: center;
		margin: 0;
		.slick-list {
			margin-bottom: 40px;
		}
		.slick-slide {
			.wr-box-people {
				// transform: scale3D(0.92, 0.92, 0.92);
				// transition: .4s all;
				// opacity: .5;
			}
		}
		// .slick-center {
		// 	.wr-box-people {
		// 		transform: scale3D(1,1,1);
		// 		opacity: 1;
		// 		border-color: $color-peacock-blue;
		// 		&:before{
		// 			opacity: 1;
		// 		}
		// 	}
		// }
		.slick-dots {
			position: static;
			padding: 5px 0;
			height: 30px;
			li {
				width: 14px;
				height: 14px;
				margin: 0 10px; 
				&.slick-active {
					button {
						width: 14px;
						height: 14px; 
						background: $color-peacock-blue;
						transition: .3s all;
					}
				}
				button {
					width: 12px;
					height: 12px;
					background: $color-powder-blue;
					border-radius: 36px;
					&:before,
					&:after {
						content: none;
					}
				}
			}
		}
		@media (min-width: $container-width){
			.slick-slide {
				.wr-box-people {
					transform: scale3D(0.92, 0.92, 0.92);
					transition: .4s all;
					opacity: .5;
				}
			}
			.slick-center {
				.wr-box-people {
					transform: scale3D(1,1,1);
					opacity: 1;
					border-color: $color-peacock-blue;
					&:before{
						opacity: 1;
					}
				}
			}
		}
	}
}