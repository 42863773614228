/* ======================= #buttons ======================= */
.wr-btns {
	text-align: center;
}
.wr-btn {
	display: inline-block;
	border-style: solid;
	border-width: 1px;
	font-size: 20px;
	font-weight: 500;
	padding: 10px 20px;
	// height: 45px;
	border-radius: 45px;
	user-select: none;
	background: none;
	border-color: $color-peacock-blue;
	color: $color-peacock-blue;
	transition: .3s all;
	&.white {
		border-color: #ffffff;
		color: #ffffff;
	}
	&.blue {
		border-color: $color-peacock-blue;
		color: $color-peacock-blue;
	}
	&.active {
		background: $color-peacock-blue;
		color: #ffffff;
		&:before {
			content: '';
		}
	}
	&.wr-subscribe:hover {
		background: $color-peacock-blue;
		color: #ffffff;
	}
	&.wr-detail-event:hover {
		background: $color-peacock-blue;
		color: #ffffff;
	}
}
[data-print-action] {
	cursor: pointer;
}
.wr-print-btns {
	.wr-wf-close,
	.wr-btn {
		display: block;
		max-width: 240px;
		margin-bottom: 10px;
		padding: 5px 15px;
		font-size: 16px;
		text-align: center;
		color: $color-peacock-blue;
		cursor: pointer;
		&:before {
			margin-right: 10px;
			font-weight: inherit;
		}
	}
	.clear-list {
		font-weight: 900;
	}
	@media (min-width: $screen-sm-min) {
		.clear-list {
			float: right;
		}
		.wr-btn {
			margin-bottom: 0;
			display: inline-block;
		}
	}
}

.wr-btn-portlet {
    background-color: #004d9b;
    color: white!important;
    display: inline-block;
	border-style: solid;
	border-width: 1px;
	font-size: 20px;
	font-weight: 500;
	padding: 10px 20px;
	// height: 45px;
	border-radius: 45px;
	user-select: none;
	border-color: $color-peacock-blue;
	margin-top: 20px;

	&:hover{
	    color: white;
	}
   }