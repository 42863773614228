.wr-region-list {
	padding: 70px 0 50px 0;
	color: $color-peacock-blue;
	text-align: center;
	background: #b4b6bf;
	.title {
		font-size: 3.2rem;
		font-weight: 200;
	}
	.teaser {
		font-size: 1.8rem;
		font-weight: 600;
	}

	.title,
	.teaser {
		max-width: 500px;
		margin: 0 auto 30px auto;
	}

	ul {
		text-align: left;
		padding-bottom: 50px;
		font-size: 0;
		line-height: 0; 
		li {
			width: 23%;
			display: inline-block;
			margin: 1%;
			text-align: center;
			vertical-align: top;
			height: 215px;
			a {
				display: block;
				padding: 10px 0; 
				color : $color-peacock-blue;
				font-size: 21px;
				font-weight: 500;
				width: 100%;
				height: 100%;
				background-color: #ffffff;
				transition: .3s all;

				figure {
					position: relative;
					font-size: 0;
					.icon-svg {
						width: 100px;
						height: 100px;
						fill: $color-peacock-blue;
						margin-bottom: 15px;
						transition: .3s all;
					}
					span {
						display: block;
						padding: 15px 0; 
						font-size: 1.6rem;
						line-height: 2.4rem;
						font-weight: 500;
						text-align: center;
						color: inherit;
					}
				}

				&:hover {
					background-color: $color-peacock-blue;
					color: #ffffff;
					.icon-svg {
						fill: #ffffff;
					}
				}
			}
		}
	}
	@media (max-width: 600px) {
		padding: 25px 0; 
		.title {
			font-size: 2.275em;
			margin: 0 0 15px;
		}
		.teaser {
			font-size: 1.275em;
		}
		ul {
			font-size: 0;
			line-height: 0;
			li {
				width: 48%; 
			}
		}
	}
}