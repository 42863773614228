.wr-tags {
	color: $color-peacock-blue;
	padding: 20px 0; 
	.title {
		font-size: 2.4rem;
		line-height: 1.34;
		font-weight: 500;
		margin-bottom: 25px;
	}
	ul {
		font-size: 0;
		line-height: 0;
	}
	li {
		display: inline-block;
		margin-right: 10px;
		font-size: 1.2rem;
		line-height: 1;
		vertical-align: middle;
		color: #fff;
		a {
			display: block;
			padding: 5px 10px;
			color: inherit;
			background: $color-gray-dk;
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 1px;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	@media (max-width: $screen-xs-min) {
		li {
			margin: 0 5px 5px 0;
		}
	}
}
.wr-search {
	position: relative;
	color: $color-peacock-blue;
	fill: $color-peacock-blue;
	.wr-field {
		background: none;
		border-color: $color-peacock-blue;
		border-width: 0 0 1px;
		border-style: solid;
		&:focus {
			border-bottom: 1px solid $color-peacock-blue;
			&::placeholder {
				font-size: 0;
			}
			& ~ button {
				.icon-svg {
					filter: drop-shadow(0 0 2px #fff);
				}
			}
		}
		&::placeholder {
			font-weight: 400;
			color: inherit;
			font-size: 1.125em;
			line-height: 2;
			transition: .3s all;
		}
	}
	button {
		position: absolute;
		right: 15px;
		top: 2px;
		appearance: none;
		background: none;
		border: none;
		box-shadow: none;
		.icon-svg {
			width: 27px;
			height: 27px;

			transition: .3s all;
		}
	}
}

.title {
	font-size: 2.857em;
	line-height: 1.5;
	font-weight: 300;
	@media (max-width: $screen-sm-min) {
		font-size: 3.2rem;
	}
}
.teaser {
	font-size: 1.45em;
	line-height: 1.5;
	font-weight: 500;
	@media (max-width: $screen-sm-min) {
		font-size: 1.2rem;
	}
}