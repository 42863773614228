// font weights
[class].font-thin {
	font-weight: 100;
}
[class].font-extralight {
	font-weight: 200;
}
[class].font-light {
	font-weight: 300;
}
[class].font-regular {
	font-weight: 400;
}
[class].font-medium {
	font-weight: 500;
}
[class].font-semibold {
	font-weight: 600;
}
[class].font-bold {
	font-weight: 700;
}
[class].font-extrabold {
	font-weight: 800;
}
[class].font-black {
	font-weight: 900;
}
// font-sizes
[class].font-1_2 {
	font-size: 1.2em;
}
[class].font-1_5 {
	font-size: 1.5em;
}
[class].font-1_75 {
	font-size: 1.75em;
}
[class].font-2 {
	font-size: 2em;
}

// backgrounds
[class].bg-peacock-blue {
	background-color: $color-peacock-blue;
	color: #ffffff;
	fill: #ffffff;
	border-color: #ffffff;
}
[class].bg-powder-blue {
	background-color: $color-powder-blue;
	color: $color-peacock-blue;
	fill: $color-peacock-blue;
	border-color: $color-peacock-blue;
}
[class].bg-navy-blue {
	background-color: $color-navy-blue;
	color: #ffffff;
	fill: #ffffff;
	border-color: #ffffff;
}
[class].bg-gray-blue {
	background-color: $color-gray-blue;
	color: #ffffff;
	fill: #ffffff;
	border-color: #ffffff;
}
[class].bg-magenta {
	background-color: $color-magenta;
	color: #ffffff;
	fill: #ffffff;
	border-color: #ffffff;
}
[class].bg-orange {
	background-color: $color-orange;
	color: #ffffff;
	fill: #ffffff;
	border-color: #ffffff;
}
[class].bg-light-gray {
	background-color: $color-gray-lt;
	color: $color-peacock-blue;
	fill: $color-peacock-blue;
	border-color: $color-peacock-blue;
}
[class].bg-medium-gray {
	background-color: $color-gray-md;
	color: $color-peacock-blue;
	fill: $color-peacock-blue;
	border-color: $color-peacock-blue;
}
[class].bg-dark-gray {
	background-color: $color-gray-dk;
	color: #ffffff;
	fill: #ffffff;
	border-color: #ffffff;
}
[class].bg-white {
	background-color: $color-white;
	color: $color-peacock-blue;
	fill: $color-peacock-blue;
	border-color: $color-peacock-blue;
}
[class].bg-white-md {
	background-color: $color-white-md;
	color: $color-peacock-blue;
	fill: $color-peacock-blue;
	border-color: $color-peacock-blue;
}

// svg fills
[class].fill-peacock-blue {
	fill: $color-peacock-blue;
}
[class].fill-powder-blue {
	fill: $color-powder-blue;
}
[class].fill-navy-blue {
	fill: $color-navy-blue;
}
[class].fill-gray-blue {
	fill: $color-gray-blue;
}
[class].fill-magenta {
	fill: $color-magenta;
}
[class].fill-orange {
	fill: $color-orange;
}
[class].fill-light-gray {
	fill: $color-gray-lt;
}
[class].fill-medium-gray {
	fill: $color-gray-md;
}
[class].fill-dark-gray {
	fill: $color-gray-dk;
}
[class].fill-white {
	fill: #ffffff;
}

// font color
[class].color-white {
	color: #ffffff;
}
[class].color-peacock-blue {
	color: $color-peacock-blue;
}
[class].color-black {
	color: $color-text;
}
[class].color-inherit {
	color: inherit;
}
.d-flex {
	display: flex;
}

$breakpoints: (
    xsm: $screen-xs - 1px,
    xs: $screen-xs,
    sm: $screen-sm,
    md: $screen-md,
    lg: $screen-lg,
);

@each $key, $value in $breakpoints {
    .d-#{$key}-flex {
        @media screen and (min-width: $value) {
            display: flex;
        }
    }
}

.scroll-lock {
    overflow: hidden;
}