.wr-hero-portlet{
	position: relative;
	// background-position: center;
	// background-size: cover;
	// background-repeat: no-repeat;
	color: #ffffff;
	min-height: 400px;
	img {
		width: 100%;
		min-height: 400px;
		@media (max-width: $screen-md-min) {
			min-height: 400px;
			object-fit: cover;
			object-fit: top center;
		}
	}
	figure {
		position: relative;
		background:  linear-gradient(to top, transparentize($color-peacock-blue, 0.25) 15%, transparent 70%);
		img {
			mix-blend-mode: multiply;
		}
		figcaption {
			position: absolute;
			right: 10px;
			bottom: 10px;
			color: #ffffff;
		}
	}
	figure ~ .content {
		color: #ffffff;
	}

	
	.content {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 10px 0;
		color: $color-peacock-blue;
		.container {
			margin: 0 auto;
		}
		.subtitle {
			padding: 10px 0;
		}
	}
	.title {
		font-size: 40px;
		font-weight: 500;
		margin: 0; 
	}
	.subtitle {
		font-size: 24px;
		font-weight: 500;
		margin: 0;
	}
	@media (max-width: $screen-xs-min) {
		.title {
			font-size: 28px;
			font-weight: 500;
			margin: 0; 
		}
		.subtitle {
			font-size: 18px;
			font-weight: 500;
			margin: 0;
		}

	}
	@media (min-width: $screen-lg-min) {
		.content {
			padding: 0 0 30px; 
		}
		.title {
			font-size: 52px;
		}
		.subtitle {
			font-size: 24px;
			padding: 20px 0; 
		}
		figure {
			img {
				min-height: 400px;
				object-fit: cover;
			}
		}
	}
}