.wr-image-slider-portlet {
	padding: 50px 0;
	color: $color-peacock-blue;
	& > .title {
		font-size: 2.85em;
		line-height: 1.25;
		font-weight: 300;
		margin: 0 0 50px;
	}
	.image-slide {
		position: relative;
		img {
			width: 100%;
		}
		figure {
			position: relative;
			margin-bottom: 10px;
			figcaption {
				position: absolute;
				bottom: 10px;
				left: 10px;
				color: #ffffff;
			}
		}
		.title {
			left: 15px;
			font-weight: 500;
			font-size: 1.15em;
		}
	}
	.slick-arrow {
		height: 70px;
		top: 85px;
		width: 35px; 
		fill: $color-peacock-blue;
		&.slick-prev {
			left: -60px; 
		}
		&.slick-next {
			right: -60px;
		}
	}
	@media (max-width: $screen-xs-min) {
		padding: 25px 0; 
		.title {
			margin: 0 0 15px;
			font-size: 2.275em;
		}
		.teaser {
			margin: 0 0 15px; 
			font-size: 1.275em;
		}
	}
}