.richtext-portlet {
	color: $color-text;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
		img {
			max-width: 100%;
			height: auto;
		}

	@media only screen and (max-width: 1200px) {
		padding: 0 10px; 
		h1 {
			font-size: 2.5em
		}
		h2 {
			font-size: 2.0em
		}
		h3 {
			font-size: 1.75em
		}
		h4 {
			font-size: 1.5em
		}
		h5 {
			font-size: 1.25em
		}
		h6 {
			font-size: 1em
		}
	}
	@media only screen and (max-width: 1200px) {
		font-size: calc(50% + .5vw + .5vh);
	}

}