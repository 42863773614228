.wr-header {
	box-shadow: 0 2px 4px rgba(0,0,0,0.5);
	color: #000;
	transition: .3s all;
	padding: 15px 0 0; 

	.wr-brand {
		font-size: 0;
		a {
			display: inline-block;
			vertical-align: middle;
		}
		.logo-svg {
			width: 160px;
			height: 55px;
			transition: .3s fill;
			-ms-fill: $color-peacock-blue;
			use {
				transition: .3s opacity;
				&:last-child {
					fill: #fff;
					opacity: 0;
				}
			}
			@media (max-width: $screen-xs-min) {
				width: 120px;
				height: 40px; 
			}
		}
	}
	.claim {
		display: inline-block;
		padding-bottom: 20px;
		padding-left: 10px;
		vertical-align: middle;
		font-size: 14px;
		font-weight: 700;
		color: $color-peacock-blue;
	}
	&.menu-open {
		background: $color-primary;
		.wr-navigation > li > *:first-child {
			color: #fff;
			&:before {
				background: #fff;
			}
		}
		.claim {
			color:#fff;
		}
		.nav-lang * {
			color: inherit;
		}
		.icon-svg {
			fill: #fff;
		}
		.nav-lang {
			.icon-svg {
				stroke: #fff;
			}
		}
		.public,
		.extranet {
			.icon-svg {
				fill: #fff;
			}
		}
		.guest {
			.icon-svg {
				stroke: #fff;
			}
		}
		.logo-svg {
			fill: #fff;
			use {
				&.logo-color {
					opacity: 0;
				}
				&.logo-white {
					opacity: 1;
				}
			}
		}
		.wr-navigation {
			.wr-menu-toggle span {
				background: #fff;
			}
		}
	}

}