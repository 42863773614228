/* ======================= #cards ======================= */
.wr-card {
	text-align: center;
	background-color: unset; 
	border: unset;
	color: #3c3c3c;

	.card-title {
		text-transform: uppercase;
		font-family: $font-primary;
		font-weight: 500;
		margin-bottom: 1rem;
		line-height: 1.5;
	}
	.card-img {
		padding: 20px 0 15px;

		.wr-card-picture {
			border-radius: 50%;
			box-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);
		}
	}
	.card-body {
		.wr-card-attribute {
			display: block;
			text-align: center;
			text-decoration: none;
		}
	}
}
