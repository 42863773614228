.wr-contact-portlet,
.wr-contacts-list {
	position: relative;
	background-color: $color-white;
	padding: 70px 0 50px;
	text-align: center;
	h2 {
		font-size: 40px;
		font-weight: 300;
		color: $color-peacock-blue;
		margin: 0 0 60px;
	}
	h3 {
		font-size: 32px;
		font-weight: 300;
		color: $color-peacock-blue;
		margin: 0 0 60px;
	}
	h4 {
		font-size: 40px;
		font-weight: 300;
		color: $color-peacock-blue;
		margin: 0 0 60px;
	}

	.title {
		font-size: 40px;
		font-weight: 300;
		color: $color-peacock-blue;
		margin: 0 0 60px;
		text-align: center;
	}

	.teaser {
		font-size: 20px;
		line-height: 1.25;
		margin-bottom: 50px;
		text-align: center;
		font-weight: 500;
		color: $color-peacock-blue;
	}
	.contact {
		text-align: inherit;
		font-size: 0;
		line-height: 0;

		&[data-entries="1"] .wr-contact-box {
            float: none;
		}

		.wr-contact-box {
			display: inline-block;
			padding: 0 10px;
			font-size: 1.3rem;
			line-height: 1.3;
			vertical-align: top;

			@media (max-width: map-get($breakpoints, "xsm")) {  // 479px
			    width: 83.333333%;
			    margin-bottom: 10px;
			}

			@media (min-width: $screen-xs-min) {
				min-width: 379px;
				margin-bottom: 10px;
			}
			.picture {
				img{
					width: 125px;
					height: 125px;
				}
				@media (min-width: $screen-md-min) {
					text-align: left;
				}
			}
			.content {
				min-height: 205px;
				padding: 20px 15px;
				background-color: white;
				text-align: left;
				border-top: 2px solid $color-powder-blue;
				border-bottom: 2px solid $color-powder-blue;
				transition: .4s all;
				word-break: break-word;
				.contact-info {
					color: $color-text;
					padding-left: 10px;

					&--alt {
						padding-left: 10px;
						@media (min-width: $screen-md-min) {
							padding: 5px 0;
						}
					}
				}
				.name {
					// margin-bottom: 10px;
					margin: 0;
					color: $color-peacock-blue;
					white-space: break-word;
					font-size: 1.125em;
					font-weight: 500;
				}
				.last-name {
					margin-bottom: 10px;
				}
				.additional {
					margin-bottom: 10px;
					p {
						margin: 0;
					}
					@media (min-width: $screen-xs-min) {
// 						min-height: 42px;
						min-height: 5 * 1.3em;
					}
				}
				.phone,
				.email {
					&:before{
						margin-right: 10px;
						vertical-align: middle;
						font-size: 1.3em;
					}
				}
				&:hover {
					border-top-color: $color-peacock-blue;
					border-bottom-color: $color-peacock-blue;
					box-shadow: 0 0 15px #a0a0a0;
				}
			}
			&.large {
				.quote {
					font-size: 1.625em;
					line-height: 1.385;
					color: #666666;
					font-style: italic;
					font-weight: 300;
					margin-bottom: 25px;
				}
				.name {
					font-weight: 500;
				}
			}

		}
	}
	&.text-left {
		@media screen and (min-width: $screen-sm-min) {
			text-align: left;
		}
	}
	&.text-right {
		@media screen and (min-width: $screen-xs-min) {
			text-align: right;
		}
	}
	&.modal-open {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $color-peacock-blue;
			opacity: 0.5;
		}
	}
	@media (max-width: $screen-xs-min) {
		padding: 25px 0;
		.title {
			font-size: 2.4em;
			margin-bottom: 15px;
		}
		.teaser {
			font-size: 1em;
		}
		.container {
			padding: 0;
		}
		.contact {
			.wr-contact-box {
				padding: 0;
			}
		}
	}
}