.wr-association-detail {
	h3 {
		color: $color-peacock-blue;
		font-size: 40px;
		font-weight: 200;
		text-align: center;
		margin: 0;
		padding: 70px 0px 50px 0;
	}

	ul {
		text-align: center;
		padding-bottom: 50px;
		li {
			width: 200px;
			// height: 200px;
			display: inline-block;
			vertical-align: top;
			margin: 0 70px;
			a {
				display: block;
				width: 100%;
				height: 100%;
				color : $color-text;
				font-size: 2.1rem;
				font-weight: 500;
				transition: .3s all;

			 	&:hover {
					color: #ffffff;
					.icon {
						fill: #ffffff;
					}

					background-color: unset;
				}

				figure {
					position: relative;
					.icon {
						transition: .3s all;
					}
					.icon-svg {
						width: 125px;
						fill: $color-text;
					}
					.region-svg {
						width: 125px;
						fill: $color-peacock-blue;
					}
					span {
						display: block;
						padding: 15px 0; 
						width: 100%;
						text-align: center;
					}
				}
			}
			&.region {
				a {
					@include invert_bg($color-peacock-blue);
				}
			}
			&.presse-news {
				a {
					@include invert_bg($color-magenta);
				}
			}
			&.kontakt,
			&.мitgliedersuche {
				a {
					@include invert_bg($color-text);
				}
			}
			&.kalender,
			&.mitglied-werden,
			&.sektionen {
				a {
					@include invert_bg($color-gray-dk);
				}
			}
		}
	}
	@media (max-width: $screen-xs-min) {
		.title {
			font-size: 2.275em;
			margin-bottom: 15px;
			padding: 25px 0 10px; 
		}
		.teaser {
			font-size: 1.275em;
		}
	}
}