/* ======================== #footer ======================== */
.wr-footer {
	background: $color-primary;
	color: #fff;
	padding-top: 50px;
	.wr-footer-top {
		margin-bottom: 75px;

		.icon-svg {
			width: 220px;
			height: 72px;
			fill: #fff;
		}
		.wr-footer-socials {
			text-align: right;
			.title {
				font-size: 1.6rem;
				line-height: 1.5;
				font-weight: 500;
				margin-bottom: 20px;
			}
			.social-links{
				font-size: 0;
				line-height: 0;
				li {
					display: inline-block;
					width: 30px;
					height: 20px;
					margin-left: 30px; 
					// background: #fff;
					.icon-svg {
						fill: #fff;
						width: 25px;
						height: 25px;  
					}

					&:first-child{
						margin-left: 0;
					}
				}
			}
		}
	}
	.wr-footer-nav {
		padding: 0;
		margin: 0;
		p {
			font-size: 12px;
			margin-bottom: 15px;
			font-weight: 700;
			letter-spacing: 2.5px;
			text-transform: uppercase;
		}
		a {
			display: block;
			padding: 10px 0;
			color: inherit;
			font-weight: 300;
			strong {
				font-size: 12px;
				margin-bottom: 15px;
				font-weight: 700;
				letter-spacing: 2.5px;
				text-transform: uppercase;
			}
		}
	}
	.btns-footer {
		padding-top: 20px;
		.wr-btn {
			font-size: 1.125em;
			padding: 5px 15px;
			font-weight: 400;

		}
	}

	.wr-copyright {
		font-size: 12px;
		line-height: 1.5;
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 2px;
		margin-bottom: 0;
	}
	.wr-footer-bottom {
		padding-bottom: 40px;
		border-bottom: 1px solid #fff;
	}
	.copyrights {
		padding: 30px 0; 
	}
	.wr-footer-links {
		padding: 0; 
		margin: 0; 
		font-size: 0;
		line-height: 0;
		li {
			display: inline-block;
			font-size: 12px;
			line-height: 1;
			padding: 0.25em 0;
			letter-spacing: 2px;
			text-transform: uppercase; 
			a {
				display: block;
				padding-left: 10px;
				margin-left: 10px;
				border-left: 1px solid #fff;
				color: #fff;
				font-weight: 500;
			}
			&:first-child {
				a {
					padding: 0;
					margin: 0;
					border: 0;
				}
			}
		}
	}

}
